import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';
import { ViewStyle } from 'react-native';

export const LabelTextField: React.FC<
  React.PropsWithChildren<{
    label: string;
    active?: boolean;
    error?: boolean;
    errorMessage?: string;
    styles?: ViewStyle;
    disabled?: boolean;
  }>
> = (props) => {
  const { label, children, active, error, errorMessage, styles = {}, disabled } = props;

  let labelColor = 'secondary';
  if (active) {
    labelColor = 'brandPrimary';
  } else if (error) {
    labelColor = 'destructive';
  }

  let borderColor = 'inputBorder';
  let borderWidth = 1;
  if (disabled) {
    borderColor = 'gray';
    borderWidth = 1;
  } else if (active) {
    borderColor = 'brandPrimary';
    borderWidth = 1;
  } else if (error) {
    borderColor = 'destructive';
    borderWidth = 2;
  }

  let backgroundColor = styles.backgroundColor ?? theme.colors.inputBackground;
  if (disabled) {
    backgroundColor = theme.colors.grayXLight;
  } else if (error && !active) {
    backgroundColor = theme.colors.destructiveXLight;
  }

  return (
    <Layout.Column>
      <OpenSans.Custom size="xs-12" color={labelColor} weight="bold-700">
        {label}
      </OpenSans.Custom>
      <Spacer.Vertical size={6} />
      <Layout.Column>
        <Layout.Column
          border={[borderWidth, 'solid', borderColor]}
          radius={4}
          px
          py={4}
          bg={backgroundColor}
          style={{
            minHeight: 40,
            borderBottomWidth: active ? 3 : undefined,
            borderBottomColor: active ? theme.colors.brandPrimary : undefined,
            borderBottomLeftRadius: active ? 0 : undefined,
            borderBottomRightRadius: active ? 0 : undefined,
            boxShadow: active ? '0px 0px 6px 0px rgba(0, 0, 0, 0.15)' : undefined,
            ...styles,
            backgroundColor,
          }}
          justify
        >
          {children}
        </Layout.Column>
      </Layout.Column>
      {error && !errorMessage ? (
        <Layout.Column>
          <Spacer.Vertical size={4} />
          <Layout.Row align px>
            <Icon name="warning" color="red" size={16} />
            <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
              {getTranslation('This field is required')}
            </OpenSans.Custom>
          </Layout.Row>
        </Layout.Column>
      ) : null}
      {error && errorMessage ? (
        <Layout.Column>
          <Spacer.Vertical size={4} />
          <Layout.Row align px>
            <Icon name="warning" color="red" size={16} />
            <Spacer.Horizontal size={8} />
            <OpenSans.Custom size="xs-12" weight="bold-700" color="destructive">
              {errorMessage}
            </OpenSans.Custom>
          </Layout.Row>
        </Layout.Column>
      ) : null}
    </Layout.Column>
  );
};
