import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DripSetup } from '../../components/Onboarding/DripSetup.component';

export const DripSetupPage: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const email = params.get('email');

  if (!email) {
    navigate('/404');
    return;
  }

  return <DripSetup email={email} />;
};
