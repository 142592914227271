import { currentUser } from '@easy-expense/auth-client';
import { useEmailVerificationNeeded } from '@easy-expense/data-firestore-client';
import { fuego } from '@easy-expense/firebase-app';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

export function VerifyEmailBanner() {
  const currUser = currentUser();
  const verificationNeeded = useEmailVerificationNeeded();

  const handleVisibilityChange = React.useCallback(() => {
    fuego.auth().currentUser?.reload();
  }, []);

  React.useEffect(() => {
    if (verificationNeeded) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [verificationNeeded]);

  if (!verificationNeeded) {
    return null;
  }

  return (
    <Layout.PressableRow
      bg="destructiveDark"
      px
      py
      onClick={async () => {
        await fuego.auth().currentUser?.reload();
        if (!fuego.auth().currentUser?.emailVerified) {
          await fuego.auth().currentUser?.sendEmailVerification();
          alert(
            getTranslation('Please check your inbox and spam folder to verify your email address.'),
          );
        } else {
          alert(getTranslation('Your email address has been verified.'));
        }
      }}
    >
      <OpenSans.Inverse center grow weight="bold-700">
        {`Verify your email (${currUser?.email})`}
      </OpenSans.Inverse>
    </Layout.PressableRow>
  );
}
