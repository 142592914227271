import { currentUser } from '@easy-expense/auth-client';
import { useCurrentWorkspace } from '@easy-expense/data-firestore-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { Environments, initializePaddle, Paddle as PaddleInstance } from '@paddle/paddle-js';
import React from 'react';

import { TeamPlanCode } from '../../types';

type PaddleItemsTeamPricings = Record<TeamPlanCode, string>;

const paddleItemsProd: PaddleItemsTeamPricings = {
  TEAMS_STARTER: 'pri_01j29wsd5zxa6fcxf8gh4bg86c',
  TEAMS_PRO: 'pri_01j29wep1gn000gxaa99emx8zh',
  TEAMS_BASIC: 'pri_01j1dg6v56b7f2nt9wbzpemtc2',
};

const paddleItemsSandbox: PaddleItemsTeamPricings = {
  TEAMS_STARTER: 'pri_01j2c87ra77w1skyere0vsqpxx',
  TEAMS_PRO: 'pri_01j2c88f76zcb06ssxksfvbsp0',
  TEAMS_BASIC: 'pri_01j2c897nbbnfd3xj6ans7503t',
};

const paddleClientTokenProd = 'live_845f6bbf84676f9f51709dd1664';
const paddleClientTokenSandbox = 'test_233ce5f6334d8e4b9a658161096';

export type PaddleCheckoutRef = {
  onCheckout: () => void;
};

type TeamsPlanCheckoutProps = {
  seats: number;
  planCode: TeamPlanCode;
  ref?: React.Ref<PaddleCheckoutRef>;
};

export const TeamsPlanCheckout = React.forwardRef<PaddleCheckoutRef, TeamsPlanCheckoutProps>(
  (props, ref) => {
    const { seats, planCode } = props;
    const [paddle, setPaddle] = React.useState<PaddleInstance>();
    const [paddleItemsPricings, setPaddleItemsPricings] = React.useState(paddleItemsProd);
    const email = currentUser()?.email ?? '';
    const currentWorkspace = useCurrentWorkspace();
    const [successUrl, setSuccessUrl] = React.useState<string>(
      `https://${window.location.host}/teams/invite`,
    );

    React.useEffect(() => {
      let paddleEnv: Environments = 'production';
      let paddleClientToken = paddleClientTokenProd;
      if (WebEnv.buildType !== 'production') {
        console.log('using sandbox paddle');
        paddleEnv = 'sandbox';
        paddleClientToken = paddleClientTokenSandbox;
        setPaddleItemsPricings(paddleItemsSandbox);
      }
      if (process.env.NODE_ENV === 'development') {
        setSuccessUrl('http://localhost:19006/teams/invite');
      }

      initializePaddle({
        environment: paddleEnv,
        token: paddleClientToken,
      })
        .then((paddleInstance: PaddleInstance | undefined) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        })
        .catch((error) => {
          console.error('ERROR with paddle: ', error);
        });
    }, []);

    function startPaddleCheckout() {
      if (!paddle) {
        alert('Something went wrong');
        return;
      }
      if (!planCode || !paddleItemsPricings[planCode]) {
        alert('No product selected');
        return;
      }
      let quantity = seats;
      if (paddleItemsPricings[planCode] === 'TEAMS_STARTER') {
        quantity = 1;
      }
      paddle.Checkout.open({
        items: [{ quantity, priceId: paddleItemsPricings[planCode] }],
        settings: { successUrl, showAddDiscounts: false },
        customer: {
          email,
        },
        customData: {
          organizationID: currentWorkspace?.org,
          workspaceID: currentWorkspace?.key,
          userID: currentUser()?.uid ?? '',
        },
      });
    }

    React.useImperativeHandle(ref, () => ({
      onCheckout: () => {
        startPaddleCheckout();
      },
    }));

    return null;
  },
);
