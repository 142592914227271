import {
  useCurrentOrganization,
  useCurrentWorkspace,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { PaddleCheckoutRef, TeamsPlanCheckout } from './TeamsPlanCheckout.component';
import { TeamPlanCode } from '../../types';
import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';

const MAX_TEAM_SIZE = 999999;
const MAX_COMPANY_NAME_LENGTH = 100;

export const TeamSetup: React.FC<React.PropsWithChildren<{ planCode: TeamPlanCode }>> = ({
  planCode,
}) => {
  const currentWorkspace = useCurrentWorkspace();
  const updateWorkspace = useWorkspaceStore((s) => s.updateWorkspace);
  const currentOrg = useCurrentOrganization();

  const [teamSize, setTeamSize] = React.useState<number>(1);
  const [companyName, setCompanyName] = React.useState<string>(currentWorkspace?.name ?? '');
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasSubscriptionAlready, setHasSubscriptionAlready] = React.useState(false);
  const checkoutRef = React.useRef<PaddleCheckoutRef>(null);

  React.useEffect(() => {
    if (currentWorkspace?.name === 'Default') {
      setCompanyName('');
    }
  }, [currentWorkspace]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setHasSubscriptionAlready(false);
    if (currentOrg && currentOrg.subscriptionExpiryDate) {
      const currentTimestamp = Date.now();
      const expiryDate = new Date(currentOrg.subscriptionExpiryDate);
      if (expiryDate.getTime() > currentTimestamp) {
        setIsLoading(false);
        setHasSubscriptionAlready(true);
        // avoid going through paddle checkout
      }
    }
  }, [currentOrg]);

  async function onCheckout() {
    setIsLoading(true);
    setHasSubscriptionAlready(false);

    if (hasSubscriptionAlready) {
      setIsLoading(false);
      navigate('/teams/invite/');
      return; // avoid going through paddle checkout
    }

    if (companyName) {
      updateWorkspace({ name: companyName });
    }
    checkoutRef.current?.onCheckout();
    setIsLoading(false);
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn>
        <Layout.Column grow>
          <OpenSans.Primary center weight="bold-700" size="3xl-38">
            {getTranslation('Set Up Your Team')}
          </OpenSans.Primary>
          <OpenSans.Primary center weight="bold-700" size="s-16">
            {getTranslation('Customize your Easy Expense workspace')}
          </OpenSans.Primary>

          <Spacer.Vertical size={48} />

          <LabelTextField label={getTranslation('What is your company called?')}>
            <Layout.Row align>
              <Icon name="storefront-outline" size={24} color={theme.colors.primary} />
              <Spacer.Horizontal />
              <OpenSans.Input
                grow
                value={companyName}
                maxLength={MAX_COMPANY_NAME_LENGTH}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const val = event.target.value;
                  if (val.length > MAX_COMPANY_NAME_LENGTH) {
                    return;
                  }
                  setCompanyName(event.target.value);
                }}
                placeholder={getTranslation('Company Name')}
                type="text"
              />
            </Layout.Row>
          </LabelTextField>

          <Spacer.Vertical size={24} />

          <LabelTextField label={getTranslation('How many users would you like to add?')}>
            <Layout.Row align>
              <Icon name="person-add-outline" size={24} color={theme.colors.primary} />
              <Spacer.Horizontal />
              <OpenSans.Input
                grow
                placeholder={getTranslation('Enter a number of users')}
                type="number"
                max={MAX_TEAM_SIZE}
                min="1"
                value={teamSize}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const val = Math.abs(parseInt(event.target.value));
                  if (val > MAX_TEAM_SIZE || val < 1) {
                    return;
                  }
                  setTeamSize(Math.abs(parseInt(event.target.value)));
                }}
              />
            </Layout.Row>
          </LabelTextField>

          <Spacer.Vertical size={48} />

          <TeamsPlanCheckout seats={teamSize} planCode={planCode} ref={checkoutRef} />

          <Button.Primary px py radius center onClick={onCheckout} disabled={isLoading}>
            {isLoading ? getTranslation('Setting up your team...') : getTranslation('Continue')}
          </Button.Primary>

          <Spacer.Vertical size={24} />

          <Spacer.Vertical size={24} />
          <Layout.Row center>
            <OpenSans.Primary weight="light-300" size="xs-12">
              {getTranslation('Questions? ')}
            </OpenSans.Primary>
            <Spacer.Horizontal size={8} />
            <a href="https://calendly.com/eli-bucher/30min">
              <OpenSans.Primary weight="light-300" size="xs-12">
                {getTranslation('Book a demo')}
              </OpenSans.Primary>
            </a>
          </Layout.Row>
        </Layout.Column>
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
