import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useSearchParams } from 'react-router-dom';

import { auth } from '../firebase/app';

export const RequireNotAuth = ({ children }: { children: JSX.Element }) => {
  const [user] = useAuthState(auth);
  const [searchParams, _] = useSearchParams();

  if (user) {
    return <Navigate to={{ pathname: '/transactions', search: searchParams.toString() }} replace />;
  }

  return children;
};
