import { UserRole } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import React from 'react';

import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const ROLE_PERMISSIONS = [
  {
    key: 'member',
    value: {
      name: getTranslation('Member (Employee or Contractor)'),
      text: getTranslation('Can only view and edit their own transactions.'),
    },
  },
  {
    key: 'manager',
    value: {
      name: getTranslation('Manager (Bookkeeper, Accountant or Manager)'),
      text: getTranslation('Can view and edit everyones transactions.'),
    },
  },
  {
    key: 'admin',
    value: {
      name: getTranslation('Admin (Owner, Administrator or Accountant)'),
      text: getTranslation('Can invite users, manage billing and settings.'),
    },
  },
];

export const PermissionsSelector: React.FC<{
  permission: UserRole;
  setPermission: (permission: UserRole) => void;
}> = ({ permission, setPermission }) => {
  return (
    <SearchDropdown
      autoCompleteValues={ROLE_PERMISSIONS}
      onSelect={(value) => setPermission(value?.key as UserRole)}
      selectedKey={permission}
      placeholder={getTranslation('Permissions Level')}
      header={getTranslation('Permissions Level')}
      error={false}
      iconicIcon="key-outline"
    />
  );
};
