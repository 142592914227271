import { StripeClientWrappers } from '@easy-expense/auth-client';
import {
  useCachedOrgMembers,
  useCachedMember,
  useIssuingStore,
} from '@easy-expense/data-firestore-client';
import { OrganizationMember } from '@easy-expense/data-schema-v2';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { EEtoStripeParse, EntityIDTypes } from '@easy-expense/utils-shared';
import React from 'react';
import { Stripe } from 'stripe';

import { AddNewModalProps } from './types';
import { SideModal } from '../SideModal.component';

export const AddNewCardholder: React.FC<React.PropsWithChildren<AddNewModalProps>> = ({
  modalOpen,
  setModalOpen,
  accountID,
}) => {
  const members = useCachedOrgMembers();
  const cardholderData = useIssuingStore().cardholders;
  const cardholderObject = React.useMemo(
    () =>
      Object.fromEntries(
        (cardholderData?.data ?? []).map((d) => [d.metadata.userID ?? d.id, d]) ?? [],
      ),
    [cardholderData],
  );
  return (
    <SideModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <Layout.Column px py>
        {members.map((m) => (
          <MemberCardholder
            key={m.userID}
            member={m}
            cardholder={cardholderObject[m.userID]}
            accountID={accountID}
          />
        ))}
      </Layout.Column>
    </SideModal>
  );
};

const MemberCardholder: React.FC<{
  accountID: EntityIDTypes.ConnectedAccountID;
  member: OrganizationMember;
  cardholder?: Stripe.Issuing.Cardholder;
}> = ({ member, cardholder, accountID }) => {
  const workspaceMember = useCachedMember(member.userID);
  const profileDisplayName = workspaceMember?.displayName ?? 'Anonymous';
  const [working, setWorking] = React.useState<boolean>(false);
  const createCardholder = React.useCallback(() => {
    if (!workspaceMember) {
      return;
    }
    const cardholderData = EEtoStripeParse.orgMemberToCardholder(workspaceMember);
    StripeClientWrappers.Cardholders.createCardholder(accountID, cardholderData)
      // TODO: async/await
      .catch((e) => {
        console.error(e);
        alert('There was an error');
      })
      .finally(() => {
        setWorking(false);
      });
  }, []);
  return (
    <Layout.Column>
      {cardholder ? (
        <Layout.Column py px radius border={[1, 'solid', 'grayLight']}>
          <OpenSans.Secondary>{profileDisplayName}</OpenSans.Secondary>
          <OpenSans.Secondary>{workspaceMember?.email ?? 'no email'}</OpenSans.Secondary>
        </Layout.Column>
      ) : (
        <Layout.PressableColumn py px radius onClick={createCardholder}>
          <OpenSans.Primary>{profileDisplayName}</OpenSans.Primary>
          <OpenSans.Secondary>
            {working ? 'Working...' : (workspaceMember?.email ?? 'no email')}
          </OpenSans.Secondary>
        </Layout.PressableColumn>
      )}
      <Spacer.Vertical />
    </Layout.Column>
  );
};
