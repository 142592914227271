import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const WorkspaceName: React.FC = () => {
  const workspace = useCurrentWorkspace();
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  const [name, setName] = React.useState<string>(workspace?.name ?? '');

  function onChange(name: string) {
    updateWorkspace({ name });
  }

  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column style={{ maxWidth: 350 }}>
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Workspace Name')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16}>
            {getTranslation('Visible to all workspace members on invites and exports')}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <LabelTextField label="" styles={{ backgroundColor: theme.colors.inputBackground }}>
          <OpenSans.Input
            name="workspaceName"
            value={name}
            type="text"
            weight="bold-700"
            placeholder="Workspace Name"
            grow
            size={28}
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            onBlur={() => onChange(name)}
            autoComplete="off"
          />
        </LabelTextField>
      </Layout.Column>
    </Layout.Row>
  );
};
