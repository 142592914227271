import { ReportWithData } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { getMRT_RowSelectionHandler, MRT_RowSelectionState } from 'material-react-table';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../Button.components';
import { ExpenseTable } from '../Expense/MaterialExpenseTable.component';
import { CloseButton } from '../Shared/CloseButton.component';

export const ReportAddTransactions: React.FC<
  React.PropsWithChildren<{
    reportKey?: string;
    onClose: () => void;
    report: ReportWithData;
    setReportDirty?: (isDirty: boolean) => void;
  }>
> = ({ onClose, report, setReportDirty }) => {
  const navigate = useNavigate();
  const expenseObjects = Data.expenses.use();
  if (!report) {
    navigate('/404');
  }

  async function onDone() {
    report.total = report.expenses.reduce((acc, expense) => {
      const expenseObj = expenseObjects.find((e) => e.key === expense);
      if (expenseObj) {
        return acc + expenseObj.total;
      }
      return acc;
    }, 0);

    onClose();
  }

  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    report.expenses.reduce((acc: any, expense) => {
      acc[expense] = true;
      return acc;
    }, {}),
  );

  React.useEffect(() => {
    if (setReportDirty) {
      setReportDirty(true);
    }
    const newSelected = Object.keys(rowSelection);
    report.expenses = newSelected;
  }, [rowSelection]);

  return (
    <Layout.Row px={24} style={{ height: '100%', width: '100%' }}>
      <Spacer.Flex />
      <Layout.Column style={{ height: '100%', minWidth: 1100 }}>
        <Layout.Row py align>
          <OpenSans.Primary weight="bold-700" size={32}>
            {getTranslation('Select and add to')} {report?.name}
          </OpenSans.Primary>
          <Spacer.Horizontal size={32} />
          <Layout.Column>
            <Button.Primary radius={100} onClick={onDone}>
              <Icon name="checkmark-done" size={20} color={theme.colors.white} />
              <Spacer.Horizontal size={8} />
              {getTranslation('Done')}
            </Button.Primary>
          </Layout.Column>
        </Layout.Row>

        <ExpenseTable
          onRowClick={(expense) => navigate(`/expense/${expense}`)}
          additionalOptions={{
            enableRowSelection: true,
            onRowSelectionChange: setRowSelection,
            state: { rowSelection },
            muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
              onClick: (event) => getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event),
              sx: { cursor: 'pointer' },
            }),
          }}
        />
      </Layout.Column>
      <Spacer.Flex />
      <CloseButton onClose={onDone} />
    </Layout.Row>
  );
};
