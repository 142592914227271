import { Icon } from '@easy-expense/ui-shared-components';
import { zIndex } from '@easy-expense/ui-web-core';

import { Button } from '../Button.components';

export const CloseButton: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Button.Icon
      px={4}
      py={4}
      style={{
        position: 'absolute',
        left: 24,
        top: 24,
        zIndex: zIndex.CloseButton,
        boxShadow: '0 0 6px rgba(0, 0, 0, 0.586)',
      }}
      onClick={onClose}
    >
      <Icon name="close-outline" size={24} />
    </Button.Icon>
  );
};
