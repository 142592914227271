import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { DripCheckout } from './DripCheckout.component';
import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { PaddleCheckoutRef } from './TeamsPlanCheckout.component';
import { Button } from '../Button.components';

export const DripSetup: React.FC<React.PropsWithChildren<{ email: string }>> = ({ email }) => {
  const checkoutRef = React.useRef<PaddleCheckoutRef>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  async function onCheckout() {
    setIsLoading(true);
    checkoutRef.current?.onCheckout();
    setIsLoading(false);
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn>
        <Layout.Column grow>
          <OpenSans.Primary center weight="bold-700" size="3xl-38">
            {getTranslation('Start your trial')}
          </OpenSans.Primary>

          <DripCheckout email={email} ref={checkoutRef} />

          <Spacer.Vertical size={24} />
          <Button.Primary px py radius center onClick={onCheckout} disabled={isLoading}>
            {getTranslation('Continue')}
          </Button.Primary>
        </Layout.Column>
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
