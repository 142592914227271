import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { Popover } from '@mui/material';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import React from 'react';

export const IconPicker: React.FC<{
  icon: string;
  onChange: (icon: string) => void;
}> = ({ icon, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Layout.PressableRow
        radius={100}
        py={4}
        px={8}
        onClick={handleClick}
        bg="white"
        border={[1, 'solid', 'inputBorder']}
      >
        <OpenSans.Primary size="s-16">{icon}</OpenSans.Primary>
      </Layout.PressableRow>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <EmojiPicker
          open={open}
          lazyLoadEmojis
          emojiStyle={'native' as EmojiStyle}
          onEmojiClick={(event) => {
            setAnchorEl(null);
            onChange(event.emoji);
          }}
        />
      </Popover>
    </>
  );
};
