import { getGenerateWorkspaceFunction } from '@easy-expense/auth-client';
import { OrganizationType, WorkspaceSettings } from '@easy-expense/data-schema-v2';
import { Alert } from 'react-native';

export const createWorkspace = async (
  type: OrganizationType,
  name?: string,
  workspaceSettings?: Partial<WorkspaceSettings>,
) => {
  try {
    console.log('creating new workspace ', { type });
    const generate = getGenerateWorkspaceFunction();
    await generate({
      type,
      name: name ?? 'Default',
      options: {
        workspaceSettings,
      },
    });
  } catch (error) {
    console.error('ERROR CREATING WORKSPACE', error);
    Alert.alert('There was an error');
  }
};
