import { currentUser } from '@easy-expense/auth-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { initializePaddle, InitializePaddleOptions, Paddle } from '@paddle/paddle-js';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const PaddleCheckout: React.FC = () => {
  const [paddle, setPaddle] = React.useState<Paddle>();

  const [searchParams, _setSearchParams] = useSearchParams();
  const transactionId = searchParams.get('invoice_id');

  React.useEffect(() => {
    let paddleConfig: InitializePaddleOptions = {
      environment: 'sandbox',
      token: 'test_233ce5f6334d8e4b9a658161096',
    };
    if (WebEnv.buildType === 'production') {
      paddleConfig = {
        environment: 'production',
        token: 'live_7065d3abffa3906834d39f7e8fd',
      };
    }

    initializePaddle(paddleConfig).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  function attemptPay() {
    if (!paddle) {
      console.error(`ERROR initializing paddle checkout for invoice ${transactionId}`);
      return;
    }
    if (!transactionId) {
      return;
    }

    paddle.Checkout.open({
      transactionId,
      customData: {
        userID: currentUser()?.uid ?? '',
      },
    });
  }
  React.useEffect(() => {
    if (!paddle || !transactionId) {
      return;
    }
    attemptPay();
  }, [paddle, transactionId]);

  return null;
};
