import { Analytics } from '@easy-expense/analytics-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { Environments, initializePaddle, Paddle as PaddleInstance } from '@paddle/paddle-js';
import React from 'react';

const paddleClientTokenProd = 'live_845f6bbf84676f9f51709dd1664';
const paddleClientTokenSandbox = 'test_233ce5f6334d8e4b9a658161096';

const productionPrice = 'pri_01jdnzjqyjqmk1tzm4x78bx6yp';
const productionDiscount = 'dsc_01jf0k5y49vv8fxxdp86r4etqr';

const devPrice = 'pri_01jft7ve17qxysapcrpm3sjkvk';
const devDiscount = 'dsc_01jft7x9dvexd8m9jytj2qhkzt';

type DripCheckoutProps = {
  email: string;
  ref?: React.Ref<PaddleCheckoutRef>;
};

export type PaddleCheckoutRef = {
  onCheckout: () => void;
};

export const DripCheckout = React.forwardRef<PaddleCheckoutRef, DripCheckoutProps>((props, ref) => {
  const { email } = props;
  const [paddle, setPaddle] = React.useState<PaddleInstance>();
  const [successUrl, setSuccessUrl] = React.useState<string>(
    `https://${window.location.host}/login`,
  );
  const price = WebEnv.buildType === 'production' ? productionPrice : devPrice;
  const discount = WebEnv.buildType === 'production' ? productionDiscount : devDiscount;

  React.useEffect(() => {
    let paddleEnv: Environments = 'production';
    let paddleClientToken = paddleClientTokenProd;
    if (WebEnv.buildType !== 'production') {
      console.log('using sandbox paddle');
      paddleEnv = 'sandbox';
      paddleClientToken = paddleClientTokenSandbox;
    }
    if (process.env.NODE_ENV === 'development') {
      setSuccessUrl('http://localhost:19006/login');
    }

    initializePaddle({
      environment: paddleEnv,
      token: paddleClientToken,
    })
      .then((paddleInstance: PaddleInstance | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      })
      .catch((error) => {
        console.error('ERROR with paddle: ', error);
      });
  }, []);

  React.useEffect(() => {
    if (paddle) {
      startPaddleCheckout();
      Analytics.track('drip_checkout_started', { email });
    }
  }, [paddle]);

  function startPaddleCheckout() {
    if (!paddle) {
      alert('Something went wrong');
      return;
    }
    paddle.Checkout.open({
      items: [{ quantity: 1, priceId: price }],
      settings: { successUrl, showAddDiscounts: false, allowLogout: false },
      customer: {
        email,
      },
      discountId: discount,
    });
  }

  React.useImperativeHandle(ref, () => ({
    onCheckout: () => {
      startPaddleCheckout();
    },
  }));

  return null;
});
