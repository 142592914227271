import { getLLMCategoryIconSuggestion } from '@easy-expense/auth-client';
import { CategoryValue } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox } from '@mui/material';
import React from 'react';

import { IconPicker } from './IconPicker.component';
import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';

const DEFAULT_ICON = '💸';

export const UpsertCategory: React.FC<{
  onDoneUpsertCategory: () => void;
  updateCategoryValue: (key: string, valueUpdate: CategoryValue) => void;
  onDiscard: () => void;
  selectedCategoryKey?: string;
}> = ({ onDoneUpsertCategory, onDiscard, selectedCategoryKey, updateCategoryValue }) => {
  const category = Data.expenseCategories.useByKey(selectedCategoryKey);
  const [categoryValue, setCategoryValue] = React.useState<Omit<CategoryValue, 'color'>>({
    icon: category?.value?.icon ?? DEFAULT_ICON,
    name: category?.value?.name ?? '',
    deductible: category?.value?.deductible ?? false,
  });
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const cachedCategories = Data.expenseCategories.use();

  React.useEffect(() => {
    setErrorMessage('');

    if (categoryValue.name === category?.value?.name) {
      return;
    }

    const doesSameNameCategoryExist = cachedCategories.some(
      (category) => category.value.name === categoryValue.name,
    );

    if (doesSameNameCategoryExist) {
      setErrorMessage('A category with the same name already exists');
    }
  }, [categoryValue.name]);

  function onSave() {
    if (errorMessage) {
      return;
    }

    if (category) {
      Data.expenseCategories.update(category.key, { value: { ...categoryValue } });
      updateCategoryValue(category.key, categoryValue);
    } else {
      const key = Data.expenseCategories.create({ value: categoryValue! });
      addEmojiToCategoryName(key, categoryValue);
    }

    onDoneUpsertCategory();
  }

  async function addEmojiToCategoryName(key: string | undefined, categoryValue: CategoryValue) {
    if (!key) {
      return;
    }
    const emojiSuggestion = await getLLMCategoryIconSuggestion()({
      categoryName: categoryValue.name,
    });
    const icon = typeof emojiSuggestion.data === 'string' ? emojiSuggestion.data : undefined;
    Data.expenseCategories.update(key, { value: { ...categoryValue, icon } });
    updateCategoryValue(key, { ...categoryValue, icon: emojiSuggestion });
  }

  return (
    <Layout.Column>
      <Layout.Row>
        {selectedCategoryKey ? (
          <Layout.Column align>
            <OpenSans.Custom
              size="xs-12"
              color="secondary"
              weight="bold-700"
              style={{ textAlign: 'center' }}
            >
              Icon
            </OpenSans.Custom>
            <Spacer.Vertical size={6} />
            <Layout.Column>
              <Layout.Column
                radius={4}
                px
                py={4}
                style={{
                  minHeight: 40,
                }}
                bg="navHeaderBackground"
                justify
              >
                <IconPicker
                  icon={categoryValue?.icon ?? DEFAULT_ICON}
                  onChange={(icon) => setCategoryValue({ ...categoryValue, icon })}
                />
              </Layout.Column>
            </Layout.Column>
          </Layout.Column>
        ) : null}

        <Spacer.Horizontal size={32} />
        <LabelTextField
          label="Name"
          active={false}
          styles={{ backgroundColor: theme.colors.inputBackground }}
          error={!!errorMessage}
          errorMessage={errorMessage}
        >
          <OpenSans.Input
            autoFocus
            name="category name"
            value={categoryValue?.name ?? ''}
            type="text"
            placeholder="Category Name"
            grow
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const name = event.target.value;
              setCategoryValue({ ...categoryValue, name });
            }}
            autoComplete="off"
          />
        </LabelTextField>

        <Spacer.Horizontal size={32} />
        <LabelTextField label="Tax Deductible">
          <Layout.Row px>
            <Checkbox
              style={{ padding: 0 }}
              checked={categoryValue?.deductible ?? undefined}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const deductible = event.target.checked;
                setCategoryValue({ ...categoryValue, deductible });
              }}
            />
          </Layout.Row>
        </LabelTextField>
      </Layout.Row>

      <Spacer.Vertical size={32} />
      <Layout.Row>
        <Button.Secondary
          onClick={onDiscard}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 4px rgba(255, 255, 255, 1)',
          }}
        >
          <Icon
            size={15}
            color={theme.colors.brandPrimary}
            style={{ paddingRight: 10 }}
            name="chevron-back"
          />
          <OpenSans.Primary
            size={15}
            weight="bold-700"
            style={{ color: theme.colors.brandPrimary }}
          >
            {getTranslation('Discard')}
          </OpenSans.Primary>
        </Button.Secondary>
        <Spacer.Horizontal size={32} />
        <Button.Primary
          onClick={onSave}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
            width: 'auto',
          }}
        >
          <OpenSans.Custom size={15} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
            {getTranslation('Save Category')}
          </OpenSans.Custom>
          <Icon
            size={15}
            color={theme.colors.buttonWhite}
            style={{ paddingLeft: 10 }}
            name="chevron-forward"
          />
        </Button.Primary>
      </Layout.Row>
    </Layout.Column>
  );
};
