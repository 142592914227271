import { ExportFileTypeValue } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Image } from 'react-native';

export const ExportFileType: React.FC<{
  fileType: ExportFileTypeValue;
  setFileType: (type: ExportFileTypeValue) => void;
}> = ({ fileType, setFileType }) => {
  return (
    <Layout.Column>
      <OpenSans.Secondary size="xs-12" color={'primary'} weight="bold-700">
        {getTranslation('File Type')}
      </OpenSans.Secondary>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <Layout.PressableRow
          onClick={() => setFileType('csv')}
          bg={'inputBackground'}
          border={fileType === 'csv' ? [2, 'solid', 'brandPrimary'] : [1, 'solid', 'inputBorder']}
          py={4}
          px
          radius={5}
          align
        >
          <Image
            source={require('../../images/assets/excel_image.png')}
            style={{
              height: 24,
              width: 24,
            }}
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary
            weight={fileType === 'csv' ? 'bold-700' : 'regular-400'}
            color={'primary'}
          >
            {getTranslation('Excel/ CSV')}
          </OpenSans.Primary>
        </Layout.PressableRow>

        <Spacer.Horizontal />
        <Layout.PressableRow
          onClick={() => setFileType('pdf')}
          bg={'inputBackground'}
          border={fileType === 'pdf' ? [2, 'solid', 'brandPrimary'] : [1, 'solid', 'inputBorder']}
          py={4}
          px
          radius={5}
          align
        >
          <Icon
            name="document-attach-outline"
            size={16}
            color={fileType === 'pdf' ? theme.colors.brandPrimary : theme.colors.primary}
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary weight={fileType === 'pdf' ? 'bold-700' : 'regular-400'}>
            {getTranslation('PDF Report')}
          </OpenSans.Primary>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
