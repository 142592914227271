import { Icon, IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../LabelTextField.component';

export const TextInput: React.FC<
  React.PropsWithChildren<{
    header: string;
    error: boolean;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    icon: IconName;
    focusedIcon: IconName;
    autoFocus?: boolean;
  }>
> = ({ header, value, onChange, error, placeholder, icon, focusedIcon, autoFocus }) => {
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);

  function onFocusTextInput() {
    setIsFocused(true);
  }

  function onBlurTextInput() {
    setIsFocused(false);
  }

  return (
    <Layout.Column ref={divRef}>
      <LabelTextField
        label={header}
        error={error}
        styles={{
          backgroundColor: theme.colors.inputBackground,
        }}
        active={isFocused}
      >
        <Layout.Row align style={{ width: '100%' }}>
          <Icon
            name={isFocused ? focusedIcon : icon}
            size={16}
            color={isFocused ? theme.colors.brandPrimary : theme.colors.primary}
          />

          <Spacer.Horizontal size={8} />
          <OpenSans.Input
            ref={inputRef}
            name="client"
            value={value}
            type="text"
            placeholder={placeholder}
            grow
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            autoComplete="off"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                inputRef.current?.blur();
              }
            }}
            autoFocus={autoFocus}
            onFocus={() => onFocusTextInput()}
            onBlur={() => onBlurTextInput()}
          />
        </Layout.Row>
      </LabelTextField>
    </Layout.Column>
  );
};
