import { EntityFilter } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const ExportTripFilters: React.FC<{
  entityFilter: EntityFilter;
  setExportFilters: (filters: EntityFilter) => void;
}> = ({ entityFilter, setExportFilters }) => {
  const allTripClasses = Data.tripClasses.use();
  const allVehicles = Data.vehicles.use();
  const allLocations = Data.locations.use();

  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Trip Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Trip Classes')}
          placeholder={getTranslation('All Trip Classes')}
          selectedKey={entityFilter.tripClasses?.length ? entityFilter.tripClasses[0] : null}
          autoCompleteValues={[
            {
              key: undefined,
              value: { name: 'All Trip Classes', iconicIcon: 'speedometer-outline' },
            },
            ...allTripClasses,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...entityFilter, tripClasses: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="speedometer-outline"
        />
        <Spacer.Horizontal />

        <SearchDropdown
          header={getTranslation('Vehicles')}
          placeholder={getTranslation('All Vehicles')}
          selectedKey={entityFilter.vehicles?.length ? entityFilter.vehicles[0] : null}
          autoCompleteValues={[
            {
              key: undefined,
              value: { name: 'All Vehicles', iconicIcon: 'car-outline' },
            },
            ...allVehicles,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...entityFilter, vehicles: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="car-outline"
        />

        <Spacer.Horizontal />

        <SearchDropdown
          header={getTranslation('Locations')}
          placeholder={getTranslation('All Locations')}
          selectedKey={entityFilter.locations?.length ? entityFilter.locations[0] : null}
          autoCompleteValues={[
            {
              key: undefined,
              value: { name: 'All Locations', iconicIcon: 'map-outline' },
            },
            ...allLocations,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...entityFilter, locations: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="map-outline"
        />
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
