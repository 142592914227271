import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

import { PaddleBillingUsage } from './PaddleBillingUsage.component';
import { PaddleCustomerInformation } from './PaddleCustomerInformation.component';

export const BillingContent: React.FC = () => {
  return (
    <Layout.Column>
      <PaddleBillingUsage />
      <PaddleCustomerInformation />
    </Layout.Column>
  );
};
