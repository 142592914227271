import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { TeamsInviteProps } from './TeamsInvite.component';
import { useIsMobile } from '../../hooks/useWindow';
import { ErrorText } from '../Form/Fields/ErrorText.component';
import { EmailSchema } from '../SignUp.form';

export const MemberAddRow: React.FC<
  React.PropsWithChildren<{
    teamMember: TeamsInviteProps;
    onChange: (teamMember: TeamsInviteProps) => void;
  }>
> = ({ teamMember, onChange }) => {
  const [email, setEmail] = React.useState<string>(teamMember.email);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [role, _setRole] = React.useState<string>(teamMember.role);

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(email);
    setEmailValid(isValid.success);
  }, [email]);

  React.useEffect(() => {
    onChange({ email, role });
  }, [email, role]);

  const isMobile = useIsMobile();

  return (
    <Layout.TableRow align center>
      <Layout.TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 8,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Layout.Row
          border={[1, 'solid', 'grayLight']}
          radius={8}
          px
          style={{
            minHeight: 40,
            backgroundColor: theme.colors.inputBackground,
            width: isMobile ? '100%' : 350,
          }}
          align
          center
        >
          <Icon name="mail-outline" size={20} />
          <Spacer.Horizontal size={10} />
          <OpenSans.Input
            name="email"
            value={email}
            type="email"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder={getTranslation('Email')}
            grow
            style={{ outline: 'none', width: '100%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
            autoComplete="off"
          />
        </Layout.Row>
      </Layout.TableCell>
      {email.length && !emailValid ? (
        <Layout.Row style={{ width: '100%' }}>
          <ErrorText>Invalid email</ErrorText>
        </Layout.Row>
      ) : null}
      {/* <Layout.TableCell center style={{ paddingBottom: 0, paddingTop: 8 }}>
        <Spacer.Horizontal />
      </Layout.TableCell> */}
      {/* <Layout.TableCell style={{ paddingBottom: 0, paddingTop: 8 }}>
        <Layout.Row
          center
          border={[1, 'solid', 'grayLight']}
          radius={8}
          px
          py={4}
          style={{
            minHeight: 40,
            backgroundColor: theme.colors.inputBackground,
            fontFamily: 'Poppins_Regular',
            position: 'relative',
          }}
        >
          <select
            value={role}
            onChange={(event) => setRole(event.target.value)}
            style={{ border: 'none', width: '100%', background: 'transparent', zIndex: 1 }}
          >
            {UserRoleSchema.options.map((role) => (
              <option key={role} value={role}>
                {titleCase(role)}
              </option>
            ))}
          </select>
        </Layout.Row>
      </Layout.TableCell> */}
    </Layout.TableRow>
  );
};
