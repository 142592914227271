import { useReviewTransactionAccountSummary } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './Button.components';
import { Modal } from './Shared/Modal.component';

export const ExpenseActions: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout.Row align>
      <OpenSans.Primary size={32} weight="bold-700">
        {getTranslation('Transactions')}
      </OpenSans.Primary>
      <Spacer.Horizontal size={32} />
      <Layout.PressableRow
        onClick={() => navigate('/expense')}
        bg="brandPrimaryXLight"
        border={[1, 'solid', 'brandPrimary']}
        py={4}
        px
        radius={100}
        align
      >
        <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
        <Spacer.Horizontal size={8} />
        <OpenSans.Pressable>{getTranslation('Add Transaction')}</OpenSans.Pressable>
      </Layout.PressableRow>
    </Layout.Row>
  );
};

export const ReceiptOptions: React.FC = () => {
  const { forwardedEmailsTransactionCount } = useReviewTransactionAccountSummary({
    filterZeroCount: true,
  });
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();

  if (forwardedEmailsTransactionCount > 0) {
    return (
      <Layout.Row>
        <Layout.PressableRow
          px={16}
          py
          border={[1, 'solid', 'brandPrimary']}
          radius={8}
          align
          bg="brandPrimaryXLight"
          onClick={() => navigate('/review')}
        >
          <Icon name="mail-outline" size={24} color={theme.colors.brandPrimary} />
          <Spacer.Horizontal size={16} />
          <Layout.Column>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {forwardedEmailsTransactionCount +
                ' ' +
                getTranslation('Email Receipts Ready for Review')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Forward to: upload@easy-expense.com')}
            </OpenSans.Primary>
          </Layout.Column>
          <Spacer.Horizontal size={8} />
        </Layout.PressableRow>

        <Modal setShowModal={setShowModal} showModal={showModal}>
          <OpenSans.Primary>
            Review your email receipts in the mobile app. Web review is coming soon!
          </OpenSans.Primary>

          <Spacer.Vertical size={48} />

          <Button.Primary
            onClick={() => {
              setShowModal(false);
            }}
            content={getTranslation('Continue')}
          />
        </Modal>
      </Layout.Row>
    );
  }

  return (
    <Layout.Row>
      <Layout.Row px={16} py border={[1, 'solid', 'grayLight']} radius={8} align bg="white">
        <Icon name="mail-outline" size={24} color={theme.colors.gray} />
        <Spacer.Horizontal size={16} />
        <Layout.Column>
          <OpenSans.Primary weight="bold-700" size="s-16">
            {getTranslation('Import emailed receipts')}
          </OpenSans.Primary>
          <OpenSans.Primary size="xs-12">
            {__DEV__
              ? getTranslation('Forward to: upload+staging@easy-expense.com')
              : getTranslation('Forward to: upload@easy-expense.com')}
          </OpenSans.Primary>
        </Layout.Column>
        <Spacer.Horizontal size={8} />
      </Layout.Row>
    </Layout.Row>
  );
};

export const TransactionListHeader: React.FC<object> = () => {
  return (
    <Layout.Column>
      <ExpenseActions />
      <Spacer.Vertical size={12} />
      <ReceiptOptions />
      <Spacer.Vertical size={16} />
      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
