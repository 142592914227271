import { EntityFilter } from '@easy-expense/data-schema-v2';
import { Layout } from '@easy-expense/ui-web-core';

import { ExportType } from '../Export/ExportDataType.component';
import { ExportDateFilter, Range } from '../Export/ExportDateFilter.component';
import { ExportExpenseFilters } from '../Export/ExportExpenseFilters.component';
import { ExportFilter } from '../Export/ExportFilters.component';
import { ExportIncomeFilters } from '../Export/ExportIncomeFilters.component';
import { ExportTotalFilters } from '../Export/ExportTotalFilters.component';
import { ExportTripFilters } from '../Export/ExportTripFilters.component';

export const EntityFilters: React.FC<{
  filter: EntityFilter;
  dataType: ExportType;
  setFilter: (filter: Partial<EntityFilter>) => void;
  range?: Range;
  hideIncomes?: boolean;
}> = ({ filter, dataType, setFilter, range = 'this month', hideIncomes = false }) => {
  return (
    <Layout.Column>
      <ExportDateFilter
        dateFilters={{
          startDate: filter.startDate,
          endDate: filter.endDate,
          range,
        }}
        setDateFilters={(dateFilters: { startDate: string | null; endDate: string | null }) => {
          setFilter({
            ...filter,
            startDate: dateFilters.startDate,
            endDate: dateFilters.endDate,
          });
        }}
      />

      <ExportFilter
        exportFilters={filter}
        setExportFilters={(entityFilter: EntityFilter) => {
          setFilter(entityFilter);
        }}
      />

      {dataType === ExportType.transactions ? (
        <>
          <ExportTotalFilters
            entityFilter={filter}
            setExportFilters={(exportFilters: EntityFilter) => {
              setFilter(exportFilters);
            }}
          />
          <ExportExpenseFilters
            entityFilter={filter}
            setExportFilters={(exportFilters: EntityFilter) => {
              setFilter(exportFilters);
            }}
          />
          {hideIncomes ? null : (
            <ExportIncomeFilters
              entityFilter={filter}
              setExportFilters={(exportFilters: EntityFilter) => {
                setFilter(exportFilters);
              }}
            />
          )}
        </>
      ) : (
        <ExportTripFilters
          entityFilter={filter}
          setExportFilters={(exportFilters: EntityFilter) => {
            setFilter(exportFilters);
          }}
        />
      )}
    </Layout.Column>
  );
};
