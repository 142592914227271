import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox } from '@mui/material';

export const WorkspaceSharePaymentMethod: React.FC = () => {
  const workspace = useCurrentWorkspace();

  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  function onChange(sharePaymentMethod: boolean) {
    if (!workspace?.settings) {
      return;
    }
    updateWorkspace({
      settings: {
        ...workspace.settings,
        hidePaymentMethods: !sharePaymentMethod,
      },
    });
  }
  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column
        style={{
          maxWidth: 350,
        }}
      >
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Share Payment Methods')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16}>
            {getTranslation(
              'Allow all users to see all payment methods (only last 4 digits of cards and accounts)',
            )}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <Checkbox
          style={{ padding: 0 }}
          checked={!workspace?.settings.hidePaymentMethods}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const show = event.target.checked;
            onChange(show);
          }}
        />
      </Layout.Column>
    </Layout.Row>
  );
};
