import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { z } from 'zod';

import { Button } from './Button.components';
import { LabelTextField } from './LabelTextField.component';
import { signUp } from '../firebase/auth';

export const EmailSchema = z.string().email();
export const PASSWORD_LENGTH = 8;

export const SignUpInvited: React.FC<{
  email: string;
}> = ({ email: inputEmail }) => {
  const [email, setEmail] = React.useState<string>(inputEmail);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [emailFocused, setEmailFocused] = React.useState<boolean>(false);
  const [isDirtyEmail, setIsDirtyEmail] = React.useState<boolean>(false);

  const [password, setPassword] = React.useState<string>('');
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = React.useState<boolean>(false);
  const [isDirtyPassword, setIsDirtyPassword] = React.useState<boolean>(false);

  const emailIsEditable = inputEmail.length === 0 || !EmailSchema.safeParse(inputEmail).success;

  let passwordErrorMessage = undefined;
  if ((isDirtyPassword || passwordFocused) && password.length === 0) {
    passwordErrorMessage = getTranslation('Password is required');
  } else if ((isDirtyPassword || passwordFocused) && password.length < PASSWORD_LENGTH) {
    passwordErrorMessage = getTranslation(`Should be at least ${PASSWORD_LENGTH} characters`);
  }

  let emailErrorMessage = undefined;
  if ((isDirtyEmail || emailFocused) && email.length === 0) {
    emailErrorMessage = getTranslation('Email is required');
  } else if ((isDirtyEmail || emailFocused) && !emailValid) {
    emailErrorMessage = getTranslation('Invalid email');
  }

  const createAccountAndWorkspace = async () => {
    setIsDirtyEmail(true);
    setIsDirtyPassword(true);
    if (email && emailValid && password && passwordValid) {
      await signUp(email, password);
    }
  };

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(email);
    setEmailValid(isValid.success);
  }, [email]);

  React.useEffect(() => {
    const isValid = password?.length !== undefined && password?.length >= PASSWORD_LENGTH;
    setPasswordValid(isValid);
  }, [password]);

  return (
    <Layout.Column grow radius style={{ width: '100%' }}>
      <Spacer.Vertical size={8} />
      <LabelTextField
        label="Email"
        active={emailFocused}
        disabled={!emailIsEditable}
        errorMessage={emailErrorMessage}
        error={!!emailErrorMessage && isDirtyEmail}
      >
        <Layout.Row align>
          <Icon name="mail-outline" size={24} color={theme.colors.primary} />
          <Spacer.Horizontal />
          <OpenSans.Input
            name="email"
            value={email}
            type="text"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder="username@email.com"
            grow
            style={{ outline: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
            autoComplete="off"
            disabled={!emailIsEditable}
            autoFocus={emailIsEditable}
            onFocus={() => {
              setEmailFocused(true);
            }}
            onBlur={() => {
              setEmailFocused(false);
              setIsDirtyEmail(true);
            }}
          />
        </Layout.Row>
      </LabelTextField>
      <Spacer.Vertical size={12} />
      <LabelTextField
        label="Set your password"
        errorMessage={passwordErrorMessage}
        active={passwordFocused}
        error={!!passwordErrorMessage && isDirtyPassword}
      >
        <Layout.Row align>
          <Icon name="lock-closed-outline" size={24} color={theme.colors.primary} />
          <Spacer.Horizontal />
          <OpenSans.Input
            name="password"
            value={password}
            type="password"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder="***********"
            grow
            style={{ outline: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            onFocus={() => {
              setPasswordFocused(true);
            }}
            onBlur={() => {
              setPasswordFocused(false);
              setIsDirtyPassword(true);
            }}
            autoComplete="off"
            autoFocus={!emailIsEditable}
          />
        </Layout.Row>
      </LabelTextField>

      <Spacer.Vertical size={24} />

      <Button.Primary
        px
        py
        radius
        center
        content={getTranslation('Create Account')}
        onClick={() => createAccountAndWorkspace()}
      />

      <Spacer.Vertical size={12} />

      <Layout.Column center>
        <p
          style={{
            maxWidth: 300,
            flex: 'wrap',
            textAlign: 'center',
            color: theme.colors.secondary,
            fontSize: 12,
          }}
        >
          {getTranslation("By creating an account you are agreeing to Easy Expense's ")}
          <a
            href="https://www.easy-expense.com/terms-of-service.html
"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Terms of Service ')}
          </a>
          {getTranslation('and') + ' '}
          <a
            href="https://www.easy-expense.com/privacy-policy.html
"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Privacy Policy')}
          </a>
        </p>
      </Layout.Column>
    </Layout.Column>
  );
};
