import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { DateFormat } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

const DateFormats = [
  {
    key: 'MM/dd/yy',
    value: {
      name: 'MM/dd/yy',
      iconicIcon: 'calendar-outline' as IconName,
    },
  },
  {
    key: 'dd/MM/yy',
    value: {
      name: 'dd/MM/yy',
      iconicIcon: 'calendar-outline' as IconName,
    },
  },
];

export const WorkspaceDateFormat: React.FC = () => {
  const workspace = useCurrentWorkspace();

  const dateFormats = DateFormats;

  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  function onChange(key?: string) {
    if (!workspace?.settings) {
      return;
    }
    if (key) {
      updateWorkspace({
        settings: {
          ...workspace.settings,
          dateFormat: key as DateFormat,
        },
      });
    }
  }

  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column
        style={{
          maxWidth: 350,
        }}
      >
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Date Format')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16}>
            {getTranslation('How do you want dates in your workspace exports to be formatted')}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <SearchDropdown
          header={''}
          placeholder={getTranslation('Date Format')}
          selectedKey={workspace?.settings.dateFormat}
          autoCompleteValues={dateFormats}
          onSelect={(c) => {
            onChange(c?.key);
          }}
          error={false}
          iconicIcon="calendar-outline"
        />
      </Layout.Column>
    </Layout.Row>
  );
};
