import {
  useWorkspaceKeysStore,
  useOwnWorkspaceMembershipPaths,
  useCachedWorkspace,
  useIsAdminRole,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { formatNameAsInitials, getIDsFromWorkspacePath } from '@easy-expense/utils-shared';
import React from 'react';
import { Image } from 'react-native';

import { Button } from './Button.components';
import LoadingSpinner from './LoadingSpinner.component';
import { Modal } from './Shared/Modal.component';
import { TextInput } from './Shared/TextInput.component';
import { createWorkspace } from '../lib/createWorkspace';

const WorkspaceRow: React.FC<
  React.PropsWithChildren<{
    onPress: () => void;
    border?: boolean;
    path?: string;
    showExpand?: boolean;
  }>
> = ({ onPress, border, path, showExpand = false }) => {
  const { workspaceID } = getIDsFromWorkspacePath(path ?? '');
  const workspace = useCachedWorkspace(workspaceID);
  const abbr = formatNameAsInitials(workspace.name);
  return (
    <Layout.Row bg="brandPrimaryLight" style={{ width: '100%' }}>
      <Layout.PressableRow
        onClick={onPress}
        radius={border ? 6 : 0}
        px={16}
        py={8}
        align
        bg="white"
        style={{ width: '100%' }}
      >
        <Layout.Column
          px={workspace.logoURL ? 0 : 8}
          py={workspace.logoURL ? 0 : 8}
          bg="brandPrimaryLight"
          radius={6}
          center
          style={{ minWidth: 32, minHeight: 32 }}
        >
          {workspace.logoURL ? (
            <Image
              source={{ uri: workspace.logoURL }}
              style={{ height: 32, width: 32, borderRadius: 8 }}
            />
          ) : (
            <OpenSans.Primary weight="bold-700" size="xs-12">
              {abbr}
            </OpenSans.Primary>
          )}
        </Layout.Column>
        <Spacer.Horizontal />
        <Layout.Column grow>
          <OpenSans.Primary
            size="s-16"
            weight="bold-700"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              maxWidth: 140,
              alignSelf: 'flex-start',
            }}
          >
            {workspace.name}
          </OpenSans.Primary>
        </Layout.Column>

        {showExpand ? <Icon name="chevron-expand-outline" size={16} color="gray" /> : null}
      </Layout.PressableRow>
    </Layout.Row>
  );
};

export const WorkspaceSwitcher: React.FC = () => {
  const [showSelector, setShowSelector] = React.useState(false);
  const [showCreateWorkspace, setShowCreateWorkspace] = React.useState(false);
  const { setCurrentWorkspacePath } = useWorkspaceKeysStore();
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [workspaceName, setWorkspaceName] = React.useState('');
  const { workspaceID } = getIDsFromWorkspacePath(currentWorkspacePath ?? '');
  const workspace = useCachedWorkspace(workspaceID);
  const [showWorkspaceNameRequired, setShowWorkspaceNameRequired] = React.useState(false);
  const isAdmin = useIsAdminRole();
  const [isLoading, setIsLoading] = React.useState(false);

  const workspacePaths = useOwnWorkspaceMembershipPaths();

  return (
    <Layout.Column style={{ width: '100%' }}>
      <WorkspaceRow
        onPress={() => {
          setShowSelector(!showSelector);
        }}
        path={currentWorkspacePath}
        showExpand={true}
      />

      {showSelector && workspacePaths && (
        <Layout.PressableColumn
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 2,
            backgroundColor: 'transparent',
          }}
          onClick={() => setShowSelector(false)}
        />
      )}

      {showSelector && workspacePaths && (
        <Layout.Column style={{ width: '100%' }}>
          <Layout.DropdownColumn style={{ width: '100%' }}>
            <Spacer.Vertical size={4} />
            <Layout.Column shadow border={[1, 'solid', 'grayLight']} bg="white">
              {workspacePaths.map((ws) => {
                return (
                  <WorkspaceRow
                    key={ws}
                    path={ws}
                    onPress={() => {
                      setCurrentWorkspacePath(ws);
                      setShowSelector(false);
                    }}
                  />
                );
              })}
              {isAdmin ? (
                <Layout.PressableRow
                  px
                  py
                  bg="white"
                  center
                  onClick={() => {
                    setShowCreateWorkspace(true);
                  }}
                >
                  <Icon name="add-circle-outline" size={16} color={theme.colors.brandPrimary} />
                  <Spacer.Horizontal size={8} />
                  <OpenSans.Pressable size={14}>Add Workspace</OpenSans.Pressable>
                </Layout.PressableRow>
              ) : null}
            </Layout.Column>
          </Layout.DropdownColumn>
        </Layout.Column>
      )}

      <Modal
        showModal={showCreateWorkspace || isLoading}
        setShowModal={(show) => {
          setShowCreateWorkspace(show);
          setShowSelector(false);
        }}
        footer={
          <Layout.Row
            style={{
              borderTop: `2px solid ${theme.colors.grayXLight}`,
            }}
            px={32}
            py={24}
            justify="flex-end"
          >
            <Button.Primary
              onClick={() => {
                if (workspaceName.length > 0) {
                  setIsLoading(true);
                  createWorkspace('business', workspaceName, workspace.settings).then(() => {
                    setIsLoading(false);
                    setShowCreateWorkspace(false);
                    setShowWorkspaceNameRequired(false);
                  });
                } else {
                  setShowWorkspaceNameRequired(true);
                }
              }}
              radius={50}
              style={{
                border: '2px solid #fff',
                boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
              }}
            >
              <OpenSans.Custom
                size={15}
                weight="bold-700"
                style={{ color: theme.colors.buttonWhite }}
              >
                Create Workspace
              </OpenSans.Custom>
              <Icon
                size={15}
                color={theme.colors.buttonWhite}
                style={{ paddingLeft: 10 }}
                name="chevron-forward"
              />
            </Button.Primary>
          </Layout.Row>
        }
      >
        {isLoading ? (
          <Layout.Column px={24}>
            <OpenSans.Primary>{getTranslation('Creating Workspace...')}</OpenSans.Primary>
            <Spacer.Vertical size={32} />
            <LoadingSpinner size={32} />
          </Layout.Column>
        ) : (
          <Layout.Column style={{ width: 400 }}>
            <OpenSans.Primary size={24} weight="bold-700">
              {getTranslation('Create Workspace')}
            </OpenSans.Primary>

            <Spacer.Vertical size={16} />

            <TextInput
              error={showWorkspaceNameRequired}
              header={getTranslation('Workspace Name')}
              placeholder={getTranslation('ABC Inc.')}
              icon="business-outline"
              focusedIcon="business"
              value={workspaceName}
              onChange={(value) => setWorkspaceName(value)}
              autoFocus
            />

            <Spacer.Vertical size={16} />
          </Layout.Column>
        )}
      </Modal>
    </Layout.Column>
  );
};
