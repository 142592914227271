import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

export enum ExportType {
  transactions = 'transactions',
  trips = 'trips',
}

export const ExportDataType: React.FC<{
  dataType: ExportType;
  setDataType: (type: ExportType) => void;
}> = ({ dataType, setDataType }) => {
  return (
    <Layout.Column>
      <OpenSans.Secondary size="xs-12" color={'primary'} weight="bold-700">
        {getTranslation('Data')}
      </OpenSans.Secondary>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <Layout.PressableRow
          onClick={() => setDataType(ExportType.transactions)}
          bg={'inputBackground'}
          border={
            dataType === ExportType.transactions
              ? [2, 'solid', 'brandPrimary']
              : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Icon
            name="cash-outline"
            size={16}
            color={
              dataType === ExportType.transactions
                ? theme.colors.brandPrimary
                : theme.colors.primary
            }
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary
            weight={dataType === ExportType.transactions ? 'bold-700' : 'regular-400'}
          >
            {getTranslation('Transactions')}
          </OpenSans.Primary>
        </Layout.PressableRow>
        <Spacer.Horizontal />
        <Layout.PressableRow
          onClick={() => setDataType(ExportType.trips)}
          bg={'inputBackground'}
          border={
            dataType === ExportType.trips
              ? [2, 'solid', 'brandPrimary']
              : [1, 'solid', 'inputBorder']
          }
          py={4}
          px
          radius={5}
          align
        >
          <Icon
            name="car-outline"
            size={16}
            color={dataType === ExportType.trips ? theme.colors.brandPrimary : theme.colors.primary}
          />
          <Spacer.Horizontal size={8} />
          <OpenSans.Primary weight={dataType === ExportType.trips ? 'bold-700' : 'regular-400'}>
            {getTranslation('Trips')}
          </OpenSans.Primary>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
