import { currentUser } from '@easy-expense/auth-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import { DownloadReportRequestSchema } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { onSnapshot } from '@firebase/firestore';
import React from 'react';

import { useCreateDownloadReportRequest } from '../../../hooks/firebase/useDownloadRequest';
import LoadingSpinner from '../../LoadingSpinner.component';

export const DownloadReportPDFButton: React.FC<{
  reportID?: string;
  saveURL: string | null;
  setSaveURL: (url: string) => void;
}> = ({ reportID, saveURL, setSaveURL }) => {
  const createDownloadReportRequest = useCreateDownloadReportRequest();
  const [loadingReportDownload, setLoadingReportDownload] = React.useState(false);
  const user = currentUser();

  React.useEffect(() => {
    if (reportID) {
      fetchReportSummary();
    }
  }, [reportID]);

  async function fetchReportSummary() {
    setLoadingReportDownload(true);

    if (!createDownloadReportRequest) {
      console.error('ERROR fetching report summary');
      return;
    }

    if (!reportID) {
      return;
    }

    setLoadingReportDownload(true);
    const reqDoc = await createDownloadReportRequest(
      DownloadReportRequestSchema.parse({
        reportID,
        ...entityFields('create', user?.uid ?? ''),
      }),
    );

    onSnapshot(reqDoc, {
      next: async (snap) => {
        const newData = snap.data();
        if (newData?.error) {
          alert('There was an error exporting your data');
        }
        if (newData?.documentURL) {
          setSaveURL(newData.documentURL);
          setLoadingReportDownload(false);
        }
      },
      error: () => {
        setLoadingReportDownload(false);
      },
    });
  }

  async function downloadPDF() {
    // create a generic function for this
    // https://linear.app/easy-expense/issue/EE-2593/create-generic-pdf-upload-function-for-reports
    if (saveURL) {
      const link = document.createElement('a');
      link.href = saveURL;
      link.download = saveURL || 'downloaded-file';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(saveURL);
    }
  }

  if (!reportID) {
    return null;
  }

  return (
    <Layout.PressableRow
      onClick={downloadPDF}
      bg="inputBackground"
      border={[1, 'solid', 'inputBorder']}
      py={4}
      px
      radius={100}
      center
      style={{ minWidth: 130 }}
      disabled={loadingReportDownload}
    >
      {!loadingReportDownload ? (
        <Icon name="cloud-download-outline" size={16} color={theme.colors.primary} />
      ) : (
        <LoadingSpinner size={16} />
      )}
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary>{getTranslation('Download PDF')}</OpenSans.Primary>
      <Spacer.Horizontal size={8} />
    </Layout.PressableRow>
  );
};
