import { Layout, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';

import { AutoCompleteRow } from './AutoCompleteRow';
import { AutoCompleteValue } from './AutoCompleteTypes';

export const BottomDropDown: React.FC<{
  flipDropDown: boolean;
  showList: boolean;
  autoCompleteValues: AutoCompleteValue[];
  onSelection: (autoComplete: AutoCompleteValue) => void;
  inputValue: string;
}> = ({ flipDropDown, showList, autoCompleteValues, onSelection, inputValue }) => {
  if (flipDropDown || !showList) {
    return null;
  }

  return (
    <Layout.Column>
      <Layout.Column
        border={!showList ? [0] : [1, 'solid', 'grayLight']}
        style={{
          borderTopWidth: 0,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
          position: 'absolute',
          zIndex: zIndex.Dropdown,
          left: 0,
          right: 0,
          maxHeight: 300,
          overflow: 'scroll',
        }}
        bg="inputBackground"
      >
        {autoCompleteValues.map((autoCompleteValue: AutoCompleteValue, index) => {
          return (
            <AutoCompleteRow
              key={index}
              autoCompleteValue={autoCompleteValue}
              onSelection={onSelection}
              inputValue={inputValue}
            />
          );
        })}
      </Layout.Column>
    </Layout.Column>
  );
};
