import { Analytics } from '@easy-expense/analytics-client';
import { handleReferralSuccessFunction } from '@easy-expense/auth-client';
import { usePromiseStore } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ReceiptReportShareImage } from '../components/Onboarding/GetStarted.component';
import {
  OnboardingActionColumn,
  OnboardingBackdrop,
} from '../components/Onboarding/OnboardingBackdrop.component';
import { SignUpForm } from '../components/SignUp.form';
import { auth } from '../firebase/app';
import { useWindowDimensions } from '../hooks/useWindow';

export const SignUp: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referralCode');
  const [searchParams, _] = useSearchParams();

  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  // @ts-expect-error
  const from = location.state?.from?.pathname || '/';

  const onSuccessSignUp = async () => {
    if (referralCode) {
      usePromiseStore
        .getState()
        .awaitInMap(handleReferralSuccessFunction()({ referralKey: referralCode }));
      Analytics.identify(user?.uid ?? null, { referralCode });
      Analytics.track('referral_signup_done', { referralCode });
    }
    if (searchParams.size > 0) {
      navigate({ pathname: '/transactions', search: searchParams.toString() });
    } else {
      navigate('/workspace-type');
    }
  };

  React.useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user]);

  React.useEffect(() => {
    if (referralCode) {
      Analytics.track('referral_signup_started', { referralCode });
    }
  }, []);

  const { width } = useWindowDimensions();

  return (
    <OnboardingBackdrop>
      {width < 900 ? null : (
        <Layout.Column style={{ width: 430 }}>
          <Layout.Row>
            <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
              {getTranslation('Stop chasing receipts.')}
            </OpenSans.Primary>
          </Layout.Row>
          <Spacer.Vertical />
          <Layout.Row>
            <OpenSans.Primary size={24} style={{ lineHeight: '120%' }}>
              {getTranslation('Receipt tracking automated.')}
            </OpenSans.Primary>
          </Layout.Row>
          <Spacer.Vertical />
          <ReceiptReportShareImage />
        </Layout.Column>
      )}
      <OnboardingActionColumn width={430}>
        <SignUpForm onSuccess={onSuccessSignUp} />
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
