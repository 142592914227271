import { useIsAdminOrManagerRole } from '@easy-expense/data-firestore-client';
import { EntityFilter } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { useMembersAutoComplete } from '../Report/ShareReportButton';
import { AutoCompleteValue } from '../Shared/Dropdown/AutoCompleteTypes';
import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const useReportsAutoComplete = (): AutoCompleteValue[] => {
  const reports = Data.reports.use();

  return React.useMemo(() => {
    return [
      ...reports.map((report) => {
        return {
          key: report.key,
          value: {
            name: report.name,

            iconicIcon: 'document-outline' as IconName,
          },
        };
      }),
    ];
  }, [reports]);
};

export const ExportFilter: React.FC<{
  exportFilters: EntityFilter;
  setExportFilters: (filters: EntityFilter) => void;
}> = ({ exportFilters, setExportFilters }) => {
  const membersValues = useMembersAutoComplete();
  const reports = useReportsAutoComplete();

  const isAdminOrManager = useIsAdminOrManagerRole();

  if (!isAdminOrManager) {
    return null;
  }

  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />

      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Created By')}
          placeholder={getTranslation('All Users')}
          selectedKey={exportFilters.createdBy?.length ? exportFilters.createdBy[0] : null}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Users', iconicIcon: 'people-circle-outline' } },
            ...membersValues,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, createdBy: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="people-circle-outline"
        />
        <Spacer.Horizontal />
        <SearchDropdown
          header={getTranslation('Reports')}
          placeholder={getTranslation('All Reports')}
          selectedKey={exportFilters.reports?.length ? exportFilters.reports[0] : null}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Reports', iconicIcon: 'document-outline' } },
            ...reports,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, reports: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="document-outline"
        />
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
