import { Analytics } from '@easy-expense/analytics-client';
import { setPasswordForInvites } from '@easy-expense/auth-client';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from 'firebase/auth';

import { auth } from '../firebase/app';

export async function signUp(email: string, password: string) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error(error);
    switch ((error as DoNotFixMeIAmAny).code) {
      case 'auth/email-already-in-use':
        try {
          await setPasswordForInvites()({ password, email });
          await login(email, password);
        } catch (err) {
          alert('Email already in use');
        }
        break;
      default:
        alert('An error has occurred');
        break;
    }
  }
}

export async function login(email: string, password: string) {
  // TODO error handling/reporting @wmatous @stephenlaughton
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    Analytics.identify(userCredential.user.uid);
    return userCredential.user;
  } catch (error) {
    console.error(error);
    switch ((error as DoNotFixMeIAmAny).code) {
      case 'auth/wrong-password':
      case 'auth/user-not-found':
        alert('Username or password is incorrect');
        break;
      default:
        alert('An error has occurred');
        break;
    }
  }
}

export async function logout() {
  return signOut(auth);
}

// TODO Login with new account does not throw error, user is not migrated
export const startGoogleLogIn = (login_hint?: string | null) => {
  const provider = new GoogleAuthProvider();
  if (login_hint) {
    provider.setCustomParameters({ login_hint });
  }
  return signInWithPopup(auth, provider);
};

export const startAppleLogIn = () => {
  const provider = new OAuthProvider('apple.com');
  return signInWithPopup(auth, provider);
};
