import { ReportStatus } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout } from '@easy-expense/ui-web-core';
import React from 'react';

import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

const STATUS_OPTIONS = [
  {
    key: 'Unsent',
    value: {
      name: 'Active',
      icon: '📝',
      color: 115,
    },
  },
  {
    key: 'Sent',
    value: {
      name: 'Sent',
      icon: '📧',
      color: 232,
    },
  },
  {
    key: 'Complete',
    value: {
      name: 'Complete',
      icon: '✅',
      color: 354,
    },
  },
];

export const ReportStatusSelector: React.FC<
  React.PropsWithChildren<{
    status: ReportStatus;
    setStatus: (status: ReportStatus) => void;
  }>
> = ({ status, setStatus }) => {
  return (
    <Layout.Row>
      <SearchDropdown
        header={getTranslation('Status')}
        placeholder={getTranslation('Status')}
        selectedKey={status}
        autoCompleteValues={STATUS_OPTIONS}
        onSelect={(status) => {
          if (status) {
            setStatus(status.key as ReportStatus);
          }
        }}
        error={false}
        iconicIcon="pricetags-outline"
      />
    </Layout.Row>
  );
};
