import { getReviewEmailExpenses } from '@easy-expense/data-firestore-client';
import { ReviewableTransaction } from '@easy-expense/data-schema-v2';
import React from 'react';

import { ReviewTransactionEdit } from '../components/Expense/Edit/ReviewTransactionEdit.component';

export const ReviewTransactionEditPage: React.FC = () => {
  const [transactions, setTransactions] = React.useState<ReviewableTransaction[]>([]);
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    setTransactions(getReviewEmailExpenses());
  }, []);

  return (
    <ReviewTransactionEdit
      reviewExpense={transactions[index]}
      next={() => {
        setIndex(index + 1);
      }}
    />
  );
};
