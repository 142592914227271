import { currentUser } from '@easy-expense/auth-client';
import {
  useFirestoreWorkspaceDocumentPath,
  DownloadExpensesRequestDatabaseOps,
  DataExportRequestCRUD,
} from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  DownloadExpenseRequestSchema,
  SummaryUnit,
  DownloadRequestFilterSchema,
  SharedFile,
  DownloadRequestFilter,
  FilteredPDFRequestFilter,
  EmailOptions,
  DataExportRequestSchema,
} from '@easy-expense/data-schema-v2';
import { useDocumentData } from '@easy-expense/firebase-hooks';
import cuid from 'cuid';
import React from 'react';

export type ContentType =
  | 'Report-pdf'
  | 'Summary-pdf'
  | 'Report-csv'
  | 'Expenses-csv'
  | 'Income-csv'
  | 'Trips-csv';

export type ExportFilterValues = DownloadRequestFilter &
  FilteredPDFRequestFilter & {
    allDates: boolean;
    contentType: ContentType;
    dateStart: Date;
    dateEnd: Date;
    recipientEmail: string;
    emailOptions: EmailOptions | null;
    headerTitle: string;
    summaryUnit: string;
    requestKey: string;
  };

export function useDownloadReport(reportID?: string) {
  const user = currentUser();
  const key = React.useRef(cuid());
  const [usedDoc, { error }] = useDocumentData(
    useFirestoreWorkspaceDocumentPath('downloadExpensesRequest', key.current),
  );
  const [addRequest] = DownloadExpensesRequestDatabaseOps.useAdd();

  const [sharedResource] = useDocumentData<SharedFile>(
    usedDoc?.sharedResourceId ? `sharedResources/${usedDoc.sharedResourceId}` : null,
  );

  async function submitDownloadRequest() {
    const filter = DownloadRequestFilterSchema.parse({
      dateStart: null,
      dateEnd: null,
      summaryUnit: SummaryUnit.Values.year,
      includeArchived: false,
      includeAttachments: false,
      splitUser: false,
      reportID: reportID ?? '',
    });

    const downloadExpenseRequest = DownloadExpenseRequestSchema.parse({
      filter,
      key: key.current,
      exportType: 'Report',
      ...entityFields('create', user?.uid ?? 'none'),
      exportFileType: 'csv',
    });

    await addRequest(downloadExpenseRequest);
  }

  return { submitDownloadRequest, error, sharedResource };
}

export const useSubmitDataExportRequest = (requestKey: string, userID: string) => {
  const [, { set }] = DataExportRequestCRUD.useRequest(requestKey);

  return {
    onSubmit: async (request: any) => {
      await set?.(
        DataExportRequestSchema.parse({
          ...request,
          key: requestKey,
          ...entityFields('create', userID),
        }),
      );
    },
  };
};
