import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { FixMe } from '@easy-expense/utils-typescript';
import React from 'react';

import { AutoCompleteValue } from './AutoCompleteTypes';
import { InputIcon } from './InputIcon';

export const AutoCompleteRow: React.FC<{
  autoCompleteValue: AutoCompleteValue;
  onSelection: (v: AutoCompleteValue) => void;
  inputValue: string;
}> = ({ autoCompleteValue, onSelection, inputValue }) => {
  const index = autoCompleteValue.value.name.toLowerCase().indexOf(inputValue?.toLowerCase() ?? '');

  const start =
    index !== undefined
      ? autoCompleteValue.value.name.substring(0, index)
      : autoCompleteValue.value.name;
  const middle =
    index !== undefined
      ? autoCompleteValue.value.name.substring(index, index + inputValue.length)
      : '';
  const end =
    index !== undefined ? autoCompleteValue.value.name.substring(index + inputValue.length) : '';

  const startSpace = start.endsWith(' ');
  const endSpace = end.startsWith(' ');
  const middleSpace = middle.endsWith(' ');

  return (
    <Layout.PressableRow
      style={{
        overflow: 'hidden',
        width: '100%',
      }}
      onClick={() => onSelection(autoCompleteValue)}
      align
      px
      py={8}
      bg="inputBackground"
      onMouseDown={(event: FixMe) => event.preventDefault()}
      radius={8}
    >
      <InputIcon autoCompleteValue={autoCompleteValue} />

      <Spacer.Horizontal size={8} />

      <Layout.Column grow>
        <OpenSans.Primary size="s-16" color="grayDark">
          {start}
          {startSpace ? <>&nbsp;</> : null}
          <OpenSans.Primary
            size="s-16"
            weight="bold-700"
            color="primary"
            style={{ textDecorationLine: 'underline' }}
          >
            {middle}
            {middleSpace ? <>&nbsp;</> : null}
          </OpenSans.Primary>
          {endSpace ? <>&nbsp;</> : null}
          {end}
        </OpenSans.Primary>

        {autoCompleteValue.value.text ? (
          <>
            <Spacer.Horizontal size={8} />
            <OpenSans.Primary size="xs-12" color="grayDark">
              {autoCompleteValue.value.text}
            </OpenSans.Primary>
          </>
        ) : null}
      </Layout.Column>
    </Layout.PressableRow>
  );
};
