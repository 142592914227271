import Data from '@easy-expense/frontend-data-layer';
import { theme } from '@easy-expense/ui-theme';
import { OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const ReportName: React.FC<
  React.PropsWithChildren<{
    onChange: (name: string) => void;
    name?: string;
    autoFocus?: boolean;
  }>
> = ({ name = '', onChange, autoFocus }) => {
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    if (!name) {
      onChange('Report #' + (Data.reports.getSize() + 1));
    }
  }, []);

  return (
    <LabelTextField
      label="Report Name"
      styles={{ backgroundColor: theme.colors.inputBackground, maxWidth: 525 }}
      active={isActive}
    >
      <OpenSans.Input
        name="report"
        value={name}
        type="text"
        weight="bold-700"
        placeholder="Report Name"
        grow
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        autoComplete="off"
        autoFocus={autoFocus}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
      />
    </LabelTextField>
  );
};
