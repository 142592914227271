import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from './Button.components';
import { LabelTextField } from './LabelTextField.component';
import { SocialAuth } from './SocialAuth.component';
import { signUp } from '../firebase/auth';

export const EmailSchema = z.string().email();
export const PASSWORD_LENGTH = __DEV__ ? 6 : 8;

export const SignUpForm: React.FC<{ onSuccess: (name?: string) => Promise<void> }> = ({
  onSuccess,
}) => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string>('');
  const [isDirtyEmail, setIsDirtyEmail] = React.useState<boolean>(false);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [emailFocused, setEmailFocused] = React.useState<boolean>(false);

  const [password, setPassword] = React.useState<string>();
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [isDirtyPassword, setIsDirtyPassword] = React.useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = React.useState<boolean>(false);

  const onSocialSuccess = async () => {
    onSuccess();
  };

  const createAccountAndWorkspace = async () => {
    if (email && emailValid && password && passwordValid) {
      const user = await signUp(email, password);
      if (user) {
        onSuccess();
      }
    } else {
      setIsDirtyEmail(true);
      setIsDirtyPassword(true);
    }
  };

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(email);
    setEmailValid(isValid.success);
  }, [email]);

  React.useEffect(() => {
    const isValid = password?.length != undefined && password?.length >= PASSWORD_LENGTH;
    setPasswordValid(isValid);
  }, [password]);

  return (
    <Layout.Column grow radius style={{ width: '100%' }}>
      <Layout.Row center>
        <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
          {getTranslation('Create Account')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={24} />
      <Layout.Row center>
        <Layout.Column grow py={1} bg="grayXLight" />
        <Spacer.Horizontal />
        <OpenSans.Secondary size="s-16">{getTranslation('SIGN UP WITH EMAIL')}</OpenSans.Secondary>
        <Spacer.Horizontal />
        <Layout.Column grow py={1} bg="grayXLight" />
      </Layout.Row>
      <Spacer.Vertical size={8} />
      <LabelTextField
        label={getTranslation('Email')}
        active={emailFocused}
        error={isDirtyEmail && !emailValid}
        errorMessage={email ? 'Invalid email' : 'Email is required'}
      >
        <Layout.Row align>
          <Icon
            name="mail-outline"
            size={24}
            color={emailFocused ? theme.colors.brandPrimary : theme.colors.primary}
          />
          <Spacer.Horizontal />
          <OpenSans.Input
            name="email"
            value={email}
            type="text"
            min="0"
            step="0.01"
            weight="light-300"
            placeholder="username@email.com"
            grow
            style={{ outline: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsDirtyEmail(true);
              setEmail(event.target.value);
            }}
            autoComplete="off"
            onFocus={() => setEmailFocused(true)}
            onBlur={() => setEmailFocused(false)}
          />
        </Layout.Row>
      </LabelTextField>
      <Spacer.Vertical size={12} />

      {email.length > 0 ? (
        <LabelTextField
          label={getTranslation('Create Your Password')}
          active={passwordFocused}
          error={isDirtyPassword && !passwordValid}
          errorMessage={
            password ? `Should be at least ${PASSWORD_LENGTH} characters` : 'Password is required'
          }
        >
          <Layout.Row align>
            <Icon
              name="lock-closed-outline"
              size={24}
              color={passwordFocused ? theme.colors.brandPrimary : theme.colors.primary}
            />
            <Spacer.Horizontal />
            <OpenSans.Input
              name="password"
              value={password}
              type="password"
              min="0"
              step="0.01"
              weight="light-300"
              placeholder="***********"
              grow
              style={{ outline: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsDirtyPassword(true);
                setPassword(event.target.value);
              }}
              autoComplete="off"
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
            />
          </Layout.Row>
        </LabelTextField>
      ) : null}

      <Spacer.Vertical size={12} />

      <Layout.Row center>
        <Layout.Column grow py={1} bg="grayXLight" />
        <Spacer.Horizontal />
        <OpenSans.Secondary size="s-16">OR</OpenSans.Secondary>
        <Spacer.Horizontal />
        <Layout.Column grow py={1} bg="grayXLight" />
      </Layout.Row>

      <Spacer.Vertical size={12} />

      <SocialAuth onSuccess={onSocialSuccess} />

      <Spacer.Vertical size={48} />

      <Button.Primary
        px
        py
        radius
        center
        content={getTranslation('Create Account')}
        onClick={() => createAccountAndWorkspace()}
      />
      <Spacer.Vertical size={24} />
      <Layout.PressableRow bg="navHeaderBackground" center onClick={() => navigate('/login')}>
        <OpenSans.Primary>
          Already have an account?
          <Spacer.Horizontal />
          <OpenSans.Pressable weight="bold-700">Log In</OpenSans.Pressable>
        </OpenSans.Primary>
      </Layout.PressableRow>
      <Spacer.Vertical size={24} />
      <Layout.Column center>
        <p
          style={{
            maxWidth: 300,
            flex: 'wrap',
            textAlign: 'center',
            color: theme.colors.secondary,
            fontSize: 12,
          }}
        >
          {getTranslation("By creating an account you are agreeing to Easy Expense's ")}
          <a
            href="https://www.easy-expense.com/terms-of-service.html
"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Terms of Service ')}
          </a>
          {getTranslation('and') + ' '}
          <a
            href="https://www.easy-expense.com/privacy-policy.html
"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Privacy Policy')}
          </a>
        </p>
      </Layout.Column>
    </Layout.Column>
  );
};
