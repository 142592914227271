import {
  useCachedMembers,
  useCachedReports,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import { EntityFilter, Expense } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import _ from 'lodash';
import { MRT_ColumnFiltersState } from 'material-react-table';
import React from 'react';

import { ExpenseWithData } from './ExpenseRow.component';

export const useConvertEntityFilterToColumnFilters = () => {
  const vendorValues = Data.vendors.use();
  const members = useCachedMembers();
  const categoryValues = Data.expenseCategories.use();
  const paymentMethodValues = Data.paymentMethods.use();
  const reports = Data.reports.use();

  const convert = (entityFilter: EntityFilter) => {
    const columns: MRT_ColumnFiltersState = [];
    let startDate: string | null = null;
    let endDate: string | null = null;
    let maxTotal: number | null = null;
    let minTotal: number | null = null;

    Object.keys(entityFilter).forEach((key: string) => {
      const filter = entityFilter[key as keyof EntityFilter];
      if (filter && key !== 'setEntityFilter') {
        switch (key) {
          case 'startDate':
            startDate = filter.toString();
            break;
          case 'endDate':
            endDate = filter.toString();
            break;
          case 'vendors':
            const vendorName = vendorValues.find((c) => entityFilter.vendors?.includes(c.key))
              ?.value.name;
            if (vendorName) {
              columns.push({ id: 'vendorValueName', value: vendorName });
            }
            break;
          case 'createdBy':
            const memberName = members.find((m) =>
              entityFilter.createdBy?.includes(m.key),
            )?.displayName;
            if (memberName) {
              columns.push({ id: 'creatorDisplayName', value: memberName });
            }
            break;
          case 'reports':
            const reportName = reports.find((m) => entityFilter.reports?.includes(m.key))?.name;
            if (reportName) {
              columns.push({ id: 'reports', value: reportName });
            }
            break;
          case 'categories':
            const categoryName = categoryValues.find((c) =>
              entityFilter.categories?.includes(c.key),
            )?.value.name;
            if (categoryName) {
              columns.push({ id: 'categoryValueName', value: [categoryName] });
            }
            break;
          case 'paymentMethods':
            const paymentMethodName = paymentMethodValues.find((c) =>
              entityFilter.paymentMethods?.includes(c.key),
            )?.value.name;
            if (paymentMethodName) {
              columns.push({ id: 'paymentMethodValueName', value: [paymentMethodName] });
            }
            break;
          case 'maxTotal':
            maxTotal = filter as number;
            break;
          case 'minTotal':
            minTotal = filter as number;
            break;
        }
      }
    });

    if (maxTotal || minTotal) {
      columns.push({ id: 'total', value: [minTotal ?? '', maxTotal ?? ''] });
    }

    if (startDate || endDate) {
      columns.push({ id: 'date', value: [startDate ?? '', endDate ?? ''] });
    }

    return columns;
  };
  return convert;
};

export const useMappedExpenseData = (expenseKeys?: string[]): ExpenseWithData[] => {
  const expenses = Data.expenses.use();
  const vendors = Data.vendors.use();
  const categories = Data.expenseCategories.use();
  const paymentMethods = Data.paymentMethods.use();
  const members = useWorkspaceStore((s) => s.workspaceMembers);
  const reports = useCachedReports();

  return React.useMemo(() => {
    return expenses
      .filter((e) => {
        if (expenseKeys) {
          return expenseKeys?.includes(e.key);
        }
        return true;
      })
      .map((e) => {
        const vendorValue = vendors?.find((v) => v.key === e.vendor)?.value;
        const categoryValue = categories?.find((c) => c.key === e.category)?.value;
        const paymentMethodValue = paymentMethods?.find((pm) => pm.key === e.paymentMethod)?.value;
        const member = members[e.createdBy];
        const creatorDisplayName = getUserDisplayName(member);
        const reportsToAdd = Object.values(reports)?.filter((r) => r.expenses?.includes(e.key));

        return {
          ...e,
          vendorValue,
          categoryValue,
          paymentMethodValue,
          vendorValueName: vendorValue?.name ?? '',
          paymentMethodName: paymentMethodValue?.name ?? '',
          categoryValueName: categoryValue?.name ?? '',
          creatorDisplayName,
          reports: reportsToAdd,
        };
      });
  }, [expenses, vendors, categories, paymentMethods, reports, members]);
};

export const applyFilters = (
  expenses: ExpenseWithData[],
  filter: (e: Expense) => boolean,
  filterUser?: string,
): ExpenseWithData[] => {
  return expenses.filter((e) => {
    if (!filter(e)) {
      return false;
    }
    if (filterUser && e.createdBy !== filterUser) {
      return false;
    }
    return true;
  });
};

export const useSearchExpenseList = (
  expenses: ExpenseWithData[],
  search?: string,
): ExpenseWithData[] => {
  const terms = [
    'vendorValue.name',
    'categoryValue.name',
    'desc',
    'total',
    'paymentMethodValue.name',
  ];

  return React.useMemo(() => {
    if (search) {
      return expenses.filter((listEntity) => {
        const entityValues = terms.map((term) => _.get(listEntity, term));

        const searchValues = entityValues.reduce((acc, entityValue) => {
          if (entityValue) {
            entityValue = typeof entityValue === 'string' ? entityValue : entityValue.toString();
            acc.push(entityValue.toLowerCase());
          }
          return acc;
        }, []);

        const expenseValuesString = searchValues.join(' ');
        return expenseValuesString.includes(search.toLowerCase());
      });
    } else {
      return expenses;
    }
  }, [expenses, search]);
};
