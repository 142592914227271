import { EntityFilter, IncomeCategory } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const ExportIncomeFilters: React.FC<{
  entityFilter: EntityFilter;
  setExportFilters: (filters: EntityFilter) => void;
}> = ({ entityFilter, setExportFilters }) => {
  const allClients = Data.clients.use();
  const incomeCategories: IncomeCategory[] = Data.incomeCategories.use();

  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Income Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Clients')}
          placeholder={getTranslation('All Clients')}
          selectedKey={entityFilter.clients?.length ? entityFilter.clients[0] : null}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Clients', iconicIcon: 'person-outline' } },
            ...allClients,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...entityFilter, clients: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="person-outline"
        />
        <Spacer.Horizontal />

        <SearchDropdown
          header={getTranslation('Income Categories')}
          placeholder={getTranslation('All Income Categories')}
          selectedKey={
            entityFilter.incomeCategories?.length ? entityFilter.incomeCategories[0] : null
          }
          autoCompleteValues={[
            {
              key: undefined,
              value: { name: 'All Income Categories', iconicIcon: 'pricetag-outline' },
            },
            ...incomeCategories,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...entityFilter, incomeCategories: c?.key ? [c.key] : null });
          }}
          error={false}
          iconicIcon="pricetag-outline"
        />
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
