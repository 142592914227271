import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const DateField: React.FC<
  React.PropsWithChildren<{
    error?: boolean;
    initialDate?: string;
    onChange: (date?: string) => void;
    disabled?: boolean;
    label?: string;
    hideIcons?: boolean;
  }>
> = ({ error, initialDate, onChange, label, hideIcons = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const date = initialDate ? new AdapterDayjs().date(initialDate) : null;

  return (
    <LabelTextField label={label ?? 'Date'} error={error} active={false}>
      <Layout.Row
        align
        style={{ width: '100%', minWidth: '200px', position: 'relative' }}
        onClick={() => setIsOpen(true)}
      >
        {!hideIcons ? (
          <Icon name="calendar-outline" size={20} style={{ paddingRight: 10 }} />
        ) : null}

        {!date ? (
          <OpenSans.Secondary grow>{getTranslation('None')}</OpenSans.Secondary>
        ) : (
          <OpenSans.Primary grow>{date.format('MMM Do, YYYY')}</OpenSans.Primary>
        )}

        {!hideIcons ? <Icon name="chevron-down" size={16} iconColor="primary" /> : null}
        <Spacer.Horizontal size={2} />
      </Layout.Row>

      <DatePicker
        value={null}
        onChange={(date) => {
          onChange(date?.format('YYYY-MM-DD'));
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        slotProps={{
          textField: {
            style: {
              height: 0,
              overflow: 'hidden',
              position: 'absolute',
              top: -16,
              left: -1,
            },
          },
        }}
      />
    </LabelTextField>
  );
};
