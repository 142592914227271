import {
  canChangeUserRole,
  useCachedMembers,
  useCurrentWorkspace,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import {
  Invite,
  OrganizationMember,
  UserRole,
  UserRoleSchema,
  UserStatusSchema,
  WorkspaceMember,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../firebase/app';

export type WorkspaceRow = {
  key?: string;
  userID?: string;
  displayName: string;
  role: string;
  email: string;
  status: status;
  lastActive: number;
  updateOptions?: UserRole[];
};

export enum status {
  Active = 'Active',
  Invited = 'Invited',
}

function getMembershipRole(params: {
  member: WorkspaceMember;
  orgMember?: OrganizationMember;
  owner: string;
}) {
  const { member, orgMember, owner } = params;
  if (member.userID === owner) {
    return 'Owner';
  }
  if (orgMember?.status === UserStatusSchema.Values.active) {
    return member.role.charAt(0).toUpperCase() + member.role.slice(1);
  }
  return orgMember?.status ?? 'Member';
}

export function useWorkspaceRows() {
  const members = useCachedMembers();
  const invites: Invite[] = Data.workspaceInvites.use();

  const workspace = useCurrentWorkspace();

  const organizationMembers = useWorkspaceStore((s) => s.organizationMembers);
  const [user] = useAuthState(auth);

  return React.useMemo(() => {
    const self = members?.find((mem) => mem.userID === user?.uid);

    const cleanMembers = members.filter((member) =>
      // members are created as soon as they are invited so this removes duplicates
      {
        if (invites.length === 0) {
          return true;
        }
        return !invites.some((invite) => invite.email === member.email);
      },
    );

    const res: WorkspaceRow[] = [
      ...cleanMembers.map((member) => {
        const orgMember = organizationMembers[member.userID];
        const updateOptions =
          orgMember?.status === UserStatusSchema.Values.active &&
          self &&
          canChangeUserRole(member, self, workspace?.createdBy)
            ? UserRoleSchema.options.filter((el) => el !== member.role)
            : [];

        return {
          key: member.key,
          userID: member.userID,
          displayName: getUserDisplayName(member),
          role: getMembershipRole({ member, orgMember, owner: workspace?.createdBy ?? '' }),
          email: member?.email ?? 'No Email',
          status: status.Active,
          lastActive: member?.updatedAt ?? 0,
          updateOptions,
        };
      }),
      ...invites.map((invite) => {
        return {
          key: invite.key,
          userID: undefined,
          displayName: getUserDisplayName(
            members?.find((m) => m.email === invite.email) ?? { email: invite.email },
          ),
          role: 'Member',
          email: invite.email,
          status: status.Invited,
          lastActive: invite.updatedAt,
          updateOptions: [],
        };
      }),
    ];

    return res;
  }, [members, organizationMembers, invites]);
}
