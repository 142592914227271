import { getPaddleBillingFunction } from '@easy-expense/auth-client/src/Callable.backend';
import { PaddleBillingUsage as BillingUsageObject } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import LoadingSpinner from '../../LoadingSpinner.component';

export const PaddleBillingUsage: React.FC = () => {
  const [billingUsage, setBillingUsage] = React.useState<BillingUsageObject | null>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    const fetchUsage = async () => {
      const usage = await getPaddleBillingFunction()();
      setBillingUsage(usage.data);
      setLoading(false);
    };

    fetchUsage();
  }, []);

  const isOverSeats = React.useMemo(() => {
    if (!billingUsage?.activeSeats || !billingUsage?.purchasedSeats) {
      return false;
    }
    return billingUsage?.activeSeats > billingUsage?.purchasedSeats;
  }, [billingUsage]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <Layout.Row>
        <Layout.Column
          border={isOverSeats ? [1, 'solid', 'destructive'] : [1, 'solid', 'primary']}
          py={8}
          px={16}
          radius={5}
          align
        >
          <Layout.Row align>
            <Layout.Column>
              <Icon
                name={'people-outline'}
                size={26}
                color={isOverSeats ? theme.colors.destructive : theme.colors.primary}
              />
            </Layout.Column>
            <Spacer.Horizontal size={12} />
            <Layout.Column>
              <OpenSans.Primary
                weight="bold-700"
                color={isOverSeats ? 'destructive' : 'primary'}
              >{`${billingUsage?.activeSeats} / ${billingUsage?.purchasedSeats}`}</OpenSans.Primary>
              <OpenSans.Primary color={isOverSeats ? 'destructive' : 'secondary'}>
                {getTranslation('Seats Used')}
              </OpenSans.Primary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={16} />
        <Layout.Column border={[1, 'solid', 'primary']} py={8} px={16} radius={5} align>
          <Layout.Row align>
            <Layout.Column>
              <Icon name={'star-outline'} size={26} color={theme.colors.primary} />
            </Layout.Column>
            <Spacer.Horizontal size={12} />
            <Layout.Column>
              <OpenSans.Primary weight="bold-700">{`${billingUsage?.activeTier}`}</OpenSans.Primary>
              <OpenSans.Secondary>{getTranslation('Your Plan')}</OpenSans.Secondary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={16} />
        <Layout.Column border={[1, 'solid', 'primary']} py={8} px={16} radius={5} align>
          <Layout.Row align>
            <Layout.Column>
              <Icon name={'calendar-outline'} size={26} color={theme.colors.primary} />
            </Layout.Column>
            <Spacer.Horizontal size={12} />
            <Layout.Column>
              <OpenSans.Primary weight="bold-700">{`${billingUsage?.nextBillingDate}`}</OpenSans.Primary>
              <OpenSans.Secondary>{getTranslation('Next Billing Date')}</OpenSans.Secondary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={16} />
        <Layout.Column border={[1, 'solid', 'primary']} py={8} px={16} radius={5} align>
          <Layout.Row align>
            <Layout.Column>
              <Icon name={'calendar-outline'} size={26} color={theme.colors.primary} />
            </Layout.Column>
            <Spacer.Horizontal size={12} />
            <Layout.Column>
              <OpenSans.Primary weight="bold-700">{`${billingUsage?.nextBillEstimate}`}</OpenSans.Primary>
              <OpenSans.Secondary>
                {getTranslation(`Per ${billingUsage?.frequency}`)}
              </OpenSans.Secondary>
            </Layout.Column>
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
