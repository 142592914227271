import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import React from 'react';
import { Image } from 'react-native';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../components/Button.components';
import { useWindowDimensions } from '../hooks/useWindow';

export const Pay: React.FC = () => {
  const [paddle, setPaddle] = React.useState<Paddle>();

  const { width } = useWindowDimensions();
  const small = width < 500;

  const [params] = useSearchParams();
  const transactionId = params.get('_ptxn');

  React.useEffect(() => {
    // TODO https://linear.app/easy-expense/issue/EE-2836/create-sandbox-paddle-environment
    initializePaddle({
      environment: 'production',
      token: 'live_7065d3abffa3906834d39f7e8fd',
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  function attemptPay() {
    if (!paddle) {
      alert('Something went wrong');
      return;
    }
    if (!transactionId) {
      alert('No product selected');
      return;
    }
    paddle.Checkout.open({
      transactionId,
    });
  }
  React.useEffect(() => {
    if (!paddle || !transactionId) {
      return;
    }
    attemptPay();
  }, [paddle, transactionId]);

  return (
    <Layout.Column style={{ minHeight: '100vh', minWidth: '100vw' }} justify center>
      <Layout.Row grow style={{ maxWidth: 600 }} justify>
        <Layout.Column grow style={{ maxWidth: 400 }} px py={48}>
          <Image
            source={require('../images/icons/logo.png')}
            style={{ width: small ? 100 : 135, height: small ? 36 : 48 }}
          />

          <Spacer.Vertical size={18} />

          <OpenSans.Primary weight="bold-700" size={small ? 'l-20' : '2xl-32'}>
            Start a Team Subscription
          </OpenSans.Primary>

          <Spacer.Vertical size={small ? 12 : 24} />
          <Button.Primary onClick={attemptPay} content="Pay Now" />
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
