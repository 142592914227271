import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  useAuthStore,
  useListenForDataUpdates,
  useListenForUserUpdates,
} from '@easy-expense/data-firestore-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { theme } from '@easy-expense/ui-theme';
import { omitNil } from '@easy-expense/utils-shared';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AppLayout } from './components/Layout.component';
import { RequireAuth } from './components/RequireAuth.component';
import { RequireManager } from './components/RequireManager.component';
import './App.css';
import { RequireNotAuth } from './components/RequiresNotAuth';
import { useFreshchat } from './hooks/freshchat';
import { useCheckIssuingPermissionsWeb } from './hooks/issuing/useCheckIssuingPermissionsWeb';
import { useAdsClickID } from './hooks/useAdsClickID';
import { useInitAuth } from './hooks/useInitAuth';
import { AppDownloadPage } from './pages/appDownload.page';
import { ExpenseEditPage } from './pages/expenseEdit.page';
import { Export } from './pages/export.page';
import { InviteAccept } from './pages/invite.page';
import { InviteAcceptSuccess } from './pages/inviteSuccess.page';
import { Invoice } from './pages/invoice.page';
import { Issuing } from './pages/issuing.page';
import { Login } from './pages/login.page';
import { DripSetupPage } from './pages/onboarding/dripSetup.page';
import { GetStartedPage } from './pages/onboarding/getStarted.page';
import { HowDoYouWantToUsePage } from './pages/onboarding/howDoYouWantToUse.page';
import { SignupTeamsPage } from './pages/onboarding/signupTeams.page';
import { TeamSetupPage } from './pages/onboarding/teamSetup.page';
import { TeamsInvitePage } from './pages/onboarding/teamsInvite.page';
import { Pay } from './pages/pay.page';
import { ReportDetailPage } from './pages/reportDetail.page';
import { Reports } from './pages/reports.page';
import { ReviewTransactionEditPage } from './pages/reviewTransactionEdit.page';
import { SettingsPage } from './pages/settings.page';
import { SignUp } from './pages/signup.page';
import { Transactions } from './pages/transactions.page';
import { Trips } from './pages/trips.page';
import { Workspace } from './pages/workspace.page';

import { getSetProfileFn } from '@easy-expense/auth-client';
import { useInitInternationalization } from '@easy-expense/intl-client';

Bugsnag.start({
  apiKey: WebEnv.bugsnag.apiKey,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>
      Oh no, something has gone wrong. Please contact support@easy-expense.com and we will sort this
      out :)
    </p>
  </div>
);

export function App() {
  useListenForUserUpdates();
  useListenForDataUpdates();
  useInitInternationalization();
  useInitAuth();
  const currentUser = useAuthStore.use.currentUser();

  const enabled = useCheckIssuingPermissionsWeb();
  useFreshchat();
  React.useEffect(() => {
    if (currentUser) {
      // FIXME (Linear EE-1672) bugsnag logging this as error
      getSetProfileFn()(omitNil({ email: currentUser.email ?? null })).catch(() => {});
    }
  }, [currentUser?.email]);

  return (
    // @ts-expect-error
    <ErrorBoundary FallbackComponent={ErrorView}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RoutesWrapper>
            <Route path="/" element={<AppLayout />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <Navigate to="/transactions" />
                  </RequireAuth>
                }
              />
              <Route
                path="/reports"
                element={
                  <RequireAuth>
                    <Reports />
                  </RequireAuth>
                }
              />
              <Route
                path="/transactions"
                element={
                  <RequireAuth>
                    <Transactions />
                  </RequireAuth>
                }
              />
              <Route
                path="/export"
                element={
                  <RequireAuth>
                    <Export />
                  </RequireAuth>
                }
              />
              <Route
                path="/trips"
                element={
                  <RequireAuth>
                    <Trips />
                  </RequireAuth>
                }
              />
              <Route
                path="/workspace"
                element={
                  <RequireAuth>
                    <RequireManager>
                      <Workspace />
                    </RequireManager>
                  </RequireAuth>
                }
              />
              <Route
                path="/settings"
                element={
                  <RequireAuth>
                    <SettingsPage />
                  </RequireAuth>
                }
              />
              {enabled ? (
                <Route
                  path="issuing"
                  element={
                    <RequireAuth>
                      <Issuing />
                    </RequireAuth>
                  }
                >
                  <Route
                    path=":entity"
                    element={
                      <RequireAuth>
                        <Issuing />
                      </RequireAuth>
                    }
                  />
                </Route>
              ) : null}
            </Route>
            <Route
              path="/expense"
              element={
                <RequireAuth>
                  <ExpenseEditPage />
                </RequireAuth>
              }
            />
            <Route
              path="/review"
              element={
                <RequireAuth>
                  <ReviewTransactionEditPage />
                </RequireAuth>
              }
            />
            <Route
              path="/expense/:expenseKey"
              element={
                <RequireAuth>
                  <ExpenseEditPage />
                </RequireAuth>
              }
            />
            <Route
              path="/report"
              element={
                <RequireAuth>
                  <ReportDetailPage />
                </RequireAuth>
              }
            />
            <Route
              path="/report/:reportKey"
              element={
                <RequireAuth>
                  <ReportDetailPage />
                </RequireAuth>
              }
            />

            <Route path="/teams/onboarding/:planCode" element={<SignupTeamsPage />} />
            <Route
              path="/teams/pay/:planCode"
              element={
                <RequireAuth>
                  <TeamSetupPage />
                </RequireAuth>
              }
            />
            <Route
              path="/teams/invite"
              element={
                <RequireAuth>
                  <TeamsInvitePage />
                </RequireAuth>
              }
            />
            <Route
              path="/workspace-type"
              element={
                <RequireAuth>
                  <HowDoYouWantToUsePage />
                </RequireAuth>
              }
            />
            <Route path="/pay" element={<Pay />} />
            <Route
              path="/login"
              element={
                <RequireNotAuth>
                  <Login />
                </RequireNotAuth>
              }
            />
            <Route
              path="/signup"
              element={
                <RequireNotAuth>
                  <SignUp />
                </RequireNotAuth>
              }
            />
            <Route
              path="/onboarding"
              element={
                <RequireNotAuth>
                  <SignUp />
                </RequireNotAuth>
              }
            />
            <Route path="/discount" element={<DripSetupPage />} />
            <Route path="/invite" element={<InviteAccept />} />
            <Route path="/accepted" element={<InviteAcceptSuccess />} />
            <Route path="/onboarding/get-started" element={<GetStartedPage />} />
            <Route path="/app-download" element={<AppDownloadPage />} />
            <Route path="/invoice/:paymentID/:accountID" element={<Invoice />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/404" element={<NotFound />} />
          </RoutesWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

const NotFound: React.FC = () => {
  return <Navigate to="login" />;
};

const RoutesWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const location = useLocation();
  useAdsClickID();
  const prevRoute = React.useRef<string>('');

  React.useEffect(() => {
    if (location.pathname !== prevRoute.current) {
      if (__DEV__) {
        console.log('Route Change:', location.pathname);
      }

      prevRoute.current = location.pathname;
    }
  }, [location]);

  return <Routes>{children}</Routes>;
};
