import { Analytics } from '@easy-expense/analytics-client';
import {
  currentUserEntityFields,
  updateReportsEntities,
  getCachedReports,
  useWorkspaceSettings,
  archiveReviewTransaction,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import {
  Category,
  ExpenseSchema,
  getExpensePath,
  ReviewableTransaction,
  ReviewEmailExpense,
  TaxRate,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CurrencyField } from './CurrencyInput.component';
import { DateField } from './DateField.component';
import { NotesField } from './NotesField.component';
import { PaymentMethodField } from './PaymentMethodField.component';
import { ReceiptViewer } from './ReceiptViewer.component';
import { ReceiptsField } from './ReceiptsField.component';
import { ReportsField } from './ReportsField.component';
import { Button } from '../../Button.components';
import LoadingSpinner from '../../LoadingSpinner.component';
import { CloseButton } from '../../Shared/CloseButton.component';
import { InsertDropdown } from '../../Shared/Dropdown/InsertableDropdown.component';
import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

export const ReviewTransactionEdit: React.FC<{
  reviewExpense?: ReviewableTransaction;
  next: () => void;
}> = ({ reviewExpense, next }) => {
  const loadedExpense = reviewExpense?.data;
  const [expense, setExpense] = React.useState<Partial<ReviewEmailExpense>>({});

  const expenseKeyRef = React.useRef<string>();
  const [reportKeys, setReportKeys] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<{ [k: string]: string[] }>();
  const allVendors = Data.vendors.use();
  const categories: Category[] = Data.expenseCategories.use();

  const navigate = useNavigate();
  const [selectedReceiptIndex, setSelectedReceiptIndex] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const [newVendorName, setNewVendorName] = React.useState<string>();
  const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
  const { workspaceSettings } = useWorkspaceSettings();
  const [updatedTotal, setUpdatedTotal] = React.useState<number>();
  const [updatedTax, setUpdatedTax] = React.useState<number>();

  const taxRate: TaxRate | undefined = React.useMemo(() => {
    const trackedTaxRate = workspaceSettings?.taxRates.find((tr) => tr.tracked);
    return trackedTaxRate;
  }, [workspaceSettings]);

  React.useEffect(() => {
    if (!loadedExpense) {
      expenseKeyRef.current = undefined;
    } else {
      if (loadedExpense.entityType === 'reviewEmailExpense') {
        const vendor =
          allVendors.find((v) => v.value.name === loadedExpense.vendor)?.key ?? 'newVendor';
        if (vendor === 'newVendor') {
          setNewVendorName(loadedExpense.vendor ?? '');
        }
        const category = categories.find((c) => c.value.name === loadedExpense.category)?.key;
        setExpense({ ...loadedExpense, vendor, category });
        setUpdatedTotal(loadedExpense.total);
        setUpdatedTax(loadedExpense.tax ?? undefined);
      }
    }
  }, [loadedExpense]);

  function onMobile() {
    if (screenWidth < 768) {
      if (
        window.confirm(
          'The Experience is better on the App! Press "OK" to download the app. "CANCEL" will load this website ',
        )
      ) {
        window.location.href = 'https://app.easy-expense.com/app-download';
      }
    }
  }

  React.useEffect(() => {
    onMobile();
    Analytics.track('email_forward_open');
  }, []);

  function onSave() {
    Analytics.track('email_forward_save');
    setErrors({});

    let vendorKey = expense.vendor;
    if (newVendorName) {
      vendorKey = Data.vendors.create({ value: { name: newVendorName } });
    }

    if (!expense.date) {
      setErrors((errors) => ({ ...errors, date: ['invalid date'] }));
      return;
    }

    try {
      const parsedExpense = ExpenseSchema.safeParse({
        ...expense,
        ...currentUserEntityFields('create'),
        entityType: 'expense',
        vendor: vendorKey,
      });

      if (!parsedExpense.success) {
        setErrors((errors) => ({ ...errors, ...parsedExpense.error.formErrors.fieldErrors }));
      } else {
        const resolvedExpenseKey = Data.expenses.create({
          ...parsedExpense.data,
          sourceType: 'email',
          vendor: vendorKey,
        });

        const createdExpensePath = getExpensePath(
          useWorkspaceKeysStore.getState().currentOrganizationKey,
          useWorkspaceKeysStore.getState().currentWorkspaceKey,
          resolvedExpenseKey,
        );
        archiveReviewTransaction(reviewExpense, createdExpensePath);
        next();

        if (resolvedExpenseKey) {
          expenseKeyRef.current = resolvedExpenseKey;
          const oldReports = getCachedReports(resolvedExpenseKey).map((r) => r.key) ?? [];
          const reportsToRemove = oldReports.filter((r) => !reportKeys.includes(r));

          updateReportsEntities({
            reportKeys,
            entityType: 'expenses',
            entityKeys: [resolvedExpenseKey],
          });

          updateReportsEntities({
            reportKeys: reportsToRemove,
            entityType: 'expenses',
            entityKeys: [resolvedExpenseKey],
            remove: true,
          });
        }
      }
    } catch (err) {
      console.error('error saving expense ', err);
      alert('Error saving expense. Please contact support.');
    }
  }

  function onDelete() {
    Analytics.track('email_forward_delete');
    archiveReviewTransaction(reviewExpense, null);
    next();
  }

  if (!loadedExpense) {
    return (
      <Layout.Column style={{ width: '100%', height: '100%' }} center>
        <OpenSans.Primary size={24} weight="bold-700">
          {getTranslation('All Emails Reviewed')}
        </OpenSans.Primary>
        <Spacer.Vertical size={32} />
        <Button.Primary
          onClick={() => navigate('/transactions')}
          content={getTranslation('Continue')}
        />
      </Layout.Column>
    );
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100%' }}>
      {loading ? (
        <Layout.Column
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: zIndex.LoadingOverlay,
          }}
        >
          <LoadingSpinner />
        </Layout.Column>
      ) : null}
      <Layout.Row style={{ height: '100%', width: '100%' }}>
        {screenWidth > 800 ? (
          <Layout.Column
            style={{ minWidth: 600, maxWidth: 800, height: '100vh' }}
            grow
            className="hide-on-mobile"
          >
            <ReceiptViewer
              receipts={expense.receipts ?? []}
              onChange={(receipts: string[]) => {
                setExpense((expense) => ({ ...expense, receipts }));
              }}
              selectedReceiptIndex={selectedReceiptIndex}
              setSelectedReceiptIndex={setSelectedReceiptIndex}
              setLoading={setLoading}
            />
          </Layout.Column>
        ) : null}

        <Layout.Column
          grow
          style={{
            height: '100vh',
            boxShadow: '-8px 0 8px rgba(31, 31, 31, 0.1)',
          }}
        >
          <Layout.Column style={{ overflow: 'scroll' }} grow py={24} px={32}>
            <Layout.Row>
              <Spacer.Flex style={{ maxWidth: 150 }} />
              <Layout.Column style={{ width: 421 }}>
                <Spacer.Vertical size={32} />

                <InsertDropdown
                  header={getTranslation('Vendor')}
                  placeholder={getTranslation('Vendor Name')}
                  selectedKey={expense.vendor}
                  autoCompleteValues={[
                    ...allVendors,
                    {
                      key: 'newVendor',
                      value: {
                        name: newVendorName ?? '',
                        iconicIcon: 'add-circle-outline',
                      },
                    },
                  ]}
                  onSelect={(v) => {
                    if (v?.key) {
                      setExpense((expense) => ({ ...expense, vendor: v?.key }));
                      setNewVendorName(undefined);
                    } else if (v?.value?.name) {
                      setNewVendorName(v?.value?.name);
                    }
                  }}
                  error={!!errors?.vendor}
                  iconicIcon="storefront-outline"
                />

                <Spacer.Vertical size={'s-16'} />

                <SearchDropdown
                  header={getTranslation('Category')}
                  placeholder={getTranslation('Category Name')}
                  selectedKey={expense.category}
                  autoCompleteValues={categories}
                  onSelect={(c) => {
                    if (c?.key) {
                      setExpense((expense) => ({ ...expense, category: c?.key }));
                    }
                  }}
                  error={!!errors?.category}
                  iconicIcon="pricetags-outline"
                />

                <Spacer.Vertical size={'s-16'} />

                <Layout.Row justify>
                  <Layout.Column style={{ width: taxRate ? '50%' : '100%', paddingRight: '8px' }}>
                    <CurrencyField
                      error={!!errors?.total}
                      onChange={(total) => {
                        setExpense((expense) => ({ ...expense, total }));
                      }}
                      initialValue={expense.total}
                      label={
                        getTranslation('Total') +
                        ((expense.total ?? 0) < 0 ? ' (' + getTranslation('refund') + ')' : '')
                      }
                      updatedValue={updatedTotal}
                      allowNegative
                    />
                  </Layout.Column>
                  {taxRate === undefined ? null : (
                    <Layout.Column style={{ width: '50%', paddingLeft: '8px' }}>
                      <CurrencyField
                        error={!!errors?.tax}
                        onChange={(tax) => setExpense((expense) => ({ ...expense, tax }))}
                        initialValue={expense?.tax ?? undefined}
                        label={taxRate.name}
                        updatedValue={updatedTax}
                      />
                    </Layout.Column>
                  )}
                </Layout.Row>

                <Spacer.Vertical size={'s-16'} />

                <PaymentMethodField
                  initialPaymentMethodKey={expense.paymentMethod}
                  onChange={(paymentMethodKey) =>
                    setExpense((expense) => ({ ...expense, paymentMethod: paymentMethodKey }))
                  }
                />

                <Spacer.Vertical size={'s-16'} />

                <DateField
                  error={!!errors?.date}
                  initialDate={expense.date}
                  disabled={loading}
                  onChange={(date) => setExpense((expense) => ({ ...expense, date }))}
                />

                <Spacer.Vertical size={'s-16'} />

                <ReportsField
                  expenseKey={expense.key}
                  onChange={(rks) => setReportKeys([...rks])}
                />

                <Spacer.Vertical size={'s-16'} />

                <NotesField
                  desc={expense.desc}
                  onChange={(notes) => setExpense((expense) => ({ ...expense, desc: notes }))}
                />

                <Spacer.Vertical size={'s-16'} />

                <ReceiptsField
                  receipts={expense.receipts ?? []}
                  onChange={(receipts) => setExpense((expense) => ({ ...expense, receipts }))}
                  selectedReceiptIndex={selectedReceiptIndex}
                  setSelectedReceiptIndex={setSelectedReceiptIndex}
                  setLoading={setLoading}
                  setExpense={() => {}}
                />

                <Spacer.Vertical size={100} />
              </Layout.Column>
              <Spacer.Flex />
            </Layout.Row>
          </Layout.Column>

          <Layout.Row
            style={{
              borderTop: `2px solid ${theme.colors.grayXLight}`,
            }}
            bg="white"
            px={32}
            py={24}
          >
            <Layout.PressableRow
              px
              py={8}
              bg="destructiveXLight"
              radius={100}
              border={[2, 'solid', 'white']}
              align
              onClick={onDelete}
            >
              <Icon name="trash-outline" size={20} color={theme.colors.destructiveDark} />
              <Spacer.Horizontal size={8} />
              <OpenSans.Primary color="destructiveDark" grow size="s-16">
                {getTranslation('Delete Expense')}
              </OpenSans.Primary>
              <Spacer.Horizontal size={4} />
            </Layout.PressableRow>

            <Spacer.Horizontal size={'m-18'} />

            <Button.Primary
              onClick={onSave}
              radius={50}
              style={{
                border: '2px solid #fff',
                boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
              }}
            >
              <OpenSans.Custom
                size={15}
                weight="bold-700"
                style={{ color: theme.colors.buttonWhite }}
              >
                {getTranslation('Save Expense')}
              </OpenSans.Custom>
              <Icon
                size={15}
                color={theme.colors.buttonWhite}
                style={{ paddingLeft: 10 }}
                name="chevron-forward"
              />
            </Button.Primary>
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>

      <CloseButton onClose={() => navigate(`/transactions`)} />
    </Layout.Column>
  );
};
