import {
  addWorkspaceMember,
  canModifyUsers,
  useCurrentWorkspace,
  useCachedMembers,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { UserRole } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { Button } from '../components/Button.components';
import { LabelTextField } from '../components/LabelTextField.component';
import LoadingSpinner from '../components/LoadingSpinner.component';
import { Modal } from '../components/Shared/Modal.component';
import { EmailSchema } from '../components/SignUpInvited.form';
import {
  PermissionsSelector,
  ROLE_PERMISSIONS,
} from '../components/WorkspaceMembers/PermissionsSelector.componet';
import { WorkspaceMemberTable } from '../components/WorkspaceMembers/WorkspaceMemberTable';
import { auth } from '../firebase/app';

const InviteFormLoader: React.FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => {
  if (!isSubmitting) {
    return null;
  }
  return (
    <Layout.Column
      style={{
        width: '100vh',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: zIndex.LoadingOverlay,
      }}
    >
      <LoadingSpinner />
    </Layout.Column>
  );
};

export const Workspace: React.FC = () => {
  return <WorkspaceManagementScreen />;
};

const InviteUserForm: React.FC<{
  isSubmitting: boolean;
  handleSubmit: (emails: string, name: string, role: UserRole) => Promise<void>;
  onClose: () => void;
}> = ({ isSubmitting, handleSubmit }) => {
  const [isEmailFocused, setIsEmailFocused] = React.useState(false);
  const [isNameFocused, setIsNameFocused] = React.useState(false);
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [emails, setEmails] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [permission, setPermission] = React.useState<UserRole>('member');

  const [dirty, setDirty] = React.useState(false);

  let emailErrorMessage = undefined;
  if ((dirty || isEmailFocused) && emails?.length === 0) {
    emailErrorMessage = getTranslation('Email is required');
  } else if (dirty && !isEmailFocused && !emailValid) {
    emailErrorMessage = getTranslation('Invalid email');
  }

  React.useEffect(() => {
    const isValid = EmailSchema.safeParse(emails);
    setEmailValid(isValid.success);
  }, [emails]);

  const [permissionText, setPermissionText] = React.useState('');

  React.useEffect(() => {
    if (ROLE_PERMISSIONS) {
      const text = ROLE_PERMISSIONS?.find((p) => p.key === permission)?.value.text ?? '';
      setPermissionText(text.toLowerCase());
    }
  }, [permission]);

  return (
    <Layout.Column bg="navHeaderBackground" px py style={{ maxWidth: 800, minWidth: 600 }}>
      <InviteFormLoader isSubmitting={isSubmitting} />
      <OpenSans.Primary weight="bold-700" size="xl-28">
        {getTranslation('Invite User to your Workspace')}
      </OpenSans.Primary>

      <Spacer.Vertical size={12} />
      <Layout.Row style={{ justifyContent: 'space-between' }}>
        <Layout.Column style={{ width: '100%' }}>
          <LabelTextField
            label="Email"
            active={isEmailFocused}
            errorMessage={emailErrorMessage}
            error={!!emailErrorMessage && dirty}
          >
            <OpenSans.Input
              name="emails"
              value={emails}
              type="email"
              weight="bold-700"
              placeholder="name@mail.com"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmails(event.target.value);
                setDirty(true);
              }}
              autoComplete="off"
              autoFocus
              onFocus={() => {
                setIsEmailFocused(true);
              }}
              onBlur={() => {
                setIsEmailFocused(false);
                setDirty(true);
              }}
            />
          </LabelTextField>
        </Layout.Column>
        <Spacer.Horizontal size={16} />
        <Layout.Column style={{ width: '100%' }}>
          <LabelTextField label="Name" active={isNameFocused}>
            <OpenSans.Input
              name="name"
              value={name}
              type="text"
              weight="bold-700"
              placeholder="Sam Smith"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              autoComplete="off"
              onFocus={() => {
                setIsNameFocused(true);
              }}
              onBlur={() => {
                setIsNameFocused(false);
              }}
            />
          </LabelTextField>
        </Layout.Column>
      </Layout.Row>

      <Spacer.Vertical size={16} />

      <PermissionsSelector
        permission={permission}
        setPermission={(p) => {
          setPermission(p);
        }}
      />

      <Spacer.Vertical size={12} />

      <Layout.Column grow />

      <OpenSans.Primary>
        <span>
          {getTranslation('Invited team members will have the ')}
          <b> {getTranslation(permission.toLocaleUpperCase())}</b>
          {getTranslation(' role and ')}
          {getTranslation(permissionText)}
        </span>
      </OpenSans.Primary>
      <OpenSans.Primary></OpenSans.Primary>
      <Spacer.Vertical size={32} />

      <Layout.Row>
        <Spacer.Flex />
        <Button.Primary
          onClick={() => handleSubmit(emails ?? '', name ?? '', permission)}
          active={emailValid && dirty && !isSubmitting}
          content="Send Invite"
          radius={50}
        />
      </Layout.Row>
    </Layout.Column>
  );
};

const InviteButton: React.FC = () => {
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const workspace = useCurrentWorkspace();
  const members = useCachedMembers();
  const [user] = useAuthState(auth);
  const self = members?.find((mem) => mem.userID === user?.uid);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const inviteUsers = async (userEmails: string, name: string, role: UserRole) => {
    setIsSubmitting(true);
    //TODO: Make user emails accept multiple emails at once
    const {
      data: { success, message },
    } = await addWorkspaceMember(userEmails, currentWorkspacePath, name, role);

    if (success) {
      setModalOpen(false);
    } else {
      console.error(message);
      alert('An error occurred');
    }
    setIsSubmitting(false);
  };

  if (self && canModifyUsers(self, workspace?.createdBy)) {
    return (
      <>
        <Layout.PressableRow
          onClick={() => setModalOpen(true)}
          bg="brandPrimaryXLight"
          border={[1, 'solid', 'brandPrimary']}
          py={4}
          px
          radius={100}
          align
        >
          <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Pressable>{getTranslation('Invite to Workspace')}</OpenSans.Pressable>
        </Layout.PressableRow>

        <Modal showModal={modalOpen} setShowModal={setModalOpen}>
          <InviteUserForm
            isSubmitting={isSubmitting}
            onClose={() => setModalOpen(false)}
            handleSubmit={(emails: string, name: string, role: UserRole) =>
              inviteUsers(emails, name, role)
            }
          />
        </Modal>
      </>
    );
  }

  return null;
};

const MemberActions: React.FC<{ navigate: ReturnType<typeof useNavigate> }> = () => (
  <Layout.Row align>
    <OpenSans.Primary size={32} weight="bold-700">
      {getTranslation('Manage Team')}
    </OpenSans.Primary>
    <Spacer.Horizontal size={32} />
    <InviteButton />
  </Layout.Row>
);

function WorkspaceManagementScreen() {
  const navigate = useNavigate();

  return (
    <Layout.Column px={32} py={8}>
      <Spacer.Vertical size={16} />

      <MemberActions navigate={navigate} />

      <Spacer.Vertical size={12} />

      <Layout.Row>
        <Layout.Row px={24} py border={[1, 'solid', 'grayLight']} radius={8} align bg="white">
          <Layout.Column>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {getTranslation('Admin')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Invite users and modify settings')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>

        <Spacer.Horizontal size={16} />

        <Layout.Row px={24} py border={[1, 'solid', 'grayLight']} radius={8} align bg="white">
          <Layout.Column>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {getTranslation('Manager')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('View and edit all expenses')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>

        <Spacer.Horizontal size={16} />

        <Layout.Row px={24} py border={[1, 'solid', 'grayLight']} radius={8} align bg="white">
          <Layout.Column>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {getTranslation('Member')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Only view and edit their own expenses')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>
      </Layout.Row>

      <Spacer.Vertical size={28} />

      <WorkspaceMemberTable />
    </Layout.Column>
  );
}
