import React from 'react';

import { TransactionContent } from '../components/AppLayout';
import { PaddleCheckout } from '../components/Invoice/PaddleCheckout.component';

export const Transactions: React.FC = () => {
  return (
    <>
      <PaddleCheckout />
      <TransactionContent />
    </>
  );
};
