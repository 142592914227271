import { Layout, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out`,
  backgroundColor: `white`,
  height: `100vh`,
};

const transitionStyles: { [key: string]: object } = {
  entering: { position: 'absolute', width: 0, right: 0, top: 0, bottom: 0 },
  entered: { position: 'absolute', width: 600, right: 0, top: 0, bottom: 0 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const SideModal: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      modalOpen: boolean;
      setModalOpen: (state: boolean) => void;
    }>
  >
> = ({ modalOpen, setModalOpen, children }) => {
  return (
    <Transition in={modalOpen} timeout={0}>
      {(state) => {
        if (modalOpen) {
          return (
            <Layout.Column
              style={{
                position: 'fixed',
                right: 0,
                top: 0,
                height: '100vh',
                width: '100vw',
                zIndex: zIndex.ModalOverlay,
              }}
            >
              <Layout.Row bg="modalBackground" style={{ height: '100%' }}>
                <Layout.Column
                  bg="modalBackground"
                  onClick={() => setModalOpen(false)}
                  style={{ height: '100%' }}
                  grow
                />
                <Layout.Column style={{ ...defaultStyle, ...transitionStyles[state] }}>
                  {children}
                </Layout.Column>
              </Layout.Row>
            </Layout.Column>
          );
        }
      }}
    </Transition>
  );
};
